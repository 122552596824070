import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ChevronRight, Minus, Plus } from 'lucide-react';

import { GET_STUDIO_TASK_HISTORY } from '@/api/studio/queries';
import { Slider } from '@/components/ui/slider';

import { SidebarButton, SidebarHeader } from './SidebarMenu';

export const StudioHistorySidebar = ({
  setOpenHistory,
  setOpenCollections,
}) => {
  const [historyValue, setHistoryValue] = useState(20);

  return (
    <div
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute top-0 right-[9%] flex h-full w-80 items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start justify-between bg-white py-6 px-2 lg:px-6">
        <div className="flex w-full flex-col gap-4">
          <SidebarHeader
            text="History"
            variant="back"
            icon={ChevronRight}
            onClick={() => {
              setOpenHistory(false);
              setOpenCollections(false);
            }}
          />
          <div className="flex w-full flex-row items-center justify-between">
            <h3 className="text-xs font-bold text-secondary">July 2024</h3>
            <div className="flex w-1/2 items-center justify-center gap-x-2">
              <div
                className="flex cursor-pointer items-center justify-center"
                onClick={() =>
                  setHistoryValue((prev) => Math.max(historyValue, prev - 1))
                }
              >
                <Minus size={18} strokeWidth={4} />
              </div>
              <Slider
                defaultValue={[historyValue / 2]}
                max={historyValue}
                step={1}
                className="w-full"
                onValueChange={(value) => setHistoryValue(value[0])}
              />
              <div
                className="flex cursor-pointer items-center justify-center"
                onClick={() =>
                  setHistoryValue((prev) => Math.min(historyValue, prev + 1))
                }
              >
                <Plus size={18} strokeWidth={4} />
              </div>
            </div>
          </div>
          <StudioHistory setHistoryValue={setHistoryValue} />
        </div>
      </div>
    </div>
  );
};

const StudioHistory = ({ setHistoryValue }) => {
  const { data, error, loading, fetchMore } = useQuery(
    GET_STUDIO_TASK_HISTORY,
    {
      variables: {},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setHistoryValue(data.studioTaskHistory?.edges.length);
      },
    },
  );

  if (loading) {
    return <StudioTabHistoryLoading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const loadMore = () => {
    fetchMore({
      variables: {
        cursor: data.studioTaskHistory?.pageInfo.endCursor,
      },
    });
  };

  const getImageClass = (orientation) => {
    switch (orientation) {
      case 'square':
        return 'aspect-square col-span-1';
      case 'portrait':
        return 'aspect-[3/4] col-span-1';
      case 'landscape':
        return 'aspect-[4/3] col-span-2';
      default:
        return 'aspect-square col-span-1';
    }
  };

  return (
    <>
      <div className="grid max-h-[calc(90vH)] grid-cols-3 gap-2 overflow-y-auto sm:max-h-[calc(100vh-10%)] sm:min-h-[30rem]">
        {data.studioTaskHistory?.edges.map((item, i) =>
          item.node.images.map((image, j) => (
            <div
              key={`${i}-${j}`}
              className={getImageClass(item.node.payload.orientation.value)}
            >
              <img
                src={image.url}
                alt=""
                className="h-full w-full rounded-md object-cover"
                loading="lazy"
              />
            </div>
          )),
        )}
      </div>
      {data.studioTaskHistory?.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <SidebarButton
            text="Load More"
            onClick={loadMore}
            className="px-12 font-bold"
          />
        </div>
      )}
    </>
  );
};

const StudioTabHistoryLoading = () => {
  return (
    <div className="grid grid-cols-3 gap-2">
      {Array.from({ length: 9 }).map((_, i) => (
        <div
          key={i}
          className="aspect-square animate-pulse rounded-md bg-gray-200"
        />
      ))}
    </div>
  );
};
