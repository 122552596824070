import { useEffect } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { useLazyQuery } from '@apollo/client';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import { GET_SIMILAR_IMAGES } from '@/api/studio/queries';
import { Heading } from '@/components/Heading';
import { Spacing } from '@/components/Spacing';
import { useStudioContext } from '@/contexts/StudioContext';
import {
  StudioDesignImage,
  StudioDesignImagesContainer,
  StudioDesignImagesLoading,
  StudioDesignTask,
} from '@/pages/general/studio/components/StudioDesignTask';

TimeAgo.addDefaultLocale(en);

function GenerationElapsed({ designTask }) {
  return (
    <span className={'tabular-nums'}>
      elapsed{' '}
      <ReactTimeAgo
        date={designTask?.startedAt}
        timeStyle={{
          steps: [
            {
              formatAs: 'second',
            },
          ],
          labels: 'mini',
        }}
        tooltip={false}
      />
    </span>
  );
}

export function SimilarImages({ designTask }) {
  const [getSimilarImages, { data, loading, error }] =
    useLazyQuery(GET_SIMILAR_IMAGES);

  useEffect(() => {
    if (designTask?.startedAt) {
      getSimilarImages({
        variables: {
          payload: designTask.payload,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designTask?.startedAt, getSimilarImages]);

  return (
    <>
      <div className={'flex flex-col gap-y-3'}>
        <Heading size={'xl'} variant={'secondary'}>
          Similar Images
        </Heading>
        {loading ? (
          <StudioDesignImagesLoading />
        ) : error ? (
          <div>error</div>
        ) : null}
        <StudioDesignImagesContainer className={'flex-wrap'}>
          {data?.studioSimilarImages?.map((item, i) => (
            <StudioDesignImage
              image={item.node.image}
              designTask={item.node}
              key={i}
            />
          ))}
        </StudioDesignImagesContainer>
      </div>
    </>
  );
}

export function StudioTabGenerate({ ...props }) {
  const { designTask } = useStudioContext();

  if (designTask?.error) {
    return (
      <>
        <div
          className={
            'align-end my-4 flex flex-row items-baseline justify-between'
          }
        >
          <Heading size={'xl'} variant={'secondary'}>
            Somethings went wrong
          </Heading>
        </div>
        <span>{designTask.error?.message}</span>
      </>
    );
  }

  return (
    <>
      <div
        className={
          'align-end my-4 flex flex-row items-baseline justify-between'
        }
      >
        <Heading size={'xl'} variant={'secondary'}>
          {designTask?.loading ? 'Generating your designs...' : 'Result'}
        </Heading>
        <span>
          {designTask?.loading && <GenerationElapsed designTask={designTask} />}
        </span>
      </div>

      <StudioDesignTask designTask={designTask} />
      <Spacing size={1.5} />
      <SimilarImages designTask={designTask} />
    </>
  );
}
