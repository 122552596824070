import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

import { cn } from '@/helpers/utils';

const HideGroupHeading = ({ className, ...props }) => {
  return (
    <div
      className={cn('collapse-group-heading', props.getClassNames())}
      onClick={() => {
        document
          .querySelector(`#${props.id}`)
          .parentElement.parentElement.classList.toggle('collapsed-group');
      }}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const HideGroupMenuList = (props) => {
  const isSearch = props.selectProps.inputValue.length > 0;

  const newProps = {
    ...props,
    children: Array.isArray(props.children)
      ? props.children.map((c, idx) =>
          isSearch || idx === 0
            ? c
            : {
                ...c,
                props: {
                  ...c.props,
                  className: 'collapsed-group',
                },
              },
        )
      : props.children,
  };

  return <components.MenuList {...newProps} />;
};

export const SelectDropdown = ({
  placeholder,
  options,
  classNames,
  isAsync = false,
  isCreatable = false,
  isMulti = false,
  onChange,
  ...props
}) => {
  const isGroup = Boolean(options ? options[0]?.options : false);

  const SelectComponent = isAsync
    ? isCreatable
      ? AsyncCreatableSelect
      : AsyncSelect
    : isCreatable
      ? CreatableSelect
      : Select;

  return (
    <SelectComponent
      classNames={{
        control: (state) =>
          cn(
            'border-charcoal border-2 px-4 py-2 outline-none w-full bg-white',
            state.isDisabled && 'bg-gray-100 cursor-not-allowed',
            classNames?.control,
          ),
        valueContainer: (state) => 'flex flex-row flex-wrap gap-2',
        menu: (state) =>
          'border-charcoal border-2 border-t-0 outline-none w-full bg-white',
        option: (state) =>
          cn(
            'hover:bg-gray-100  cursor-pointer px-4 py-2',
            state.isFocused && 'bg-gray-100',
          ),
        group: (state) => cn('border-b-2 border-charcoal/10'),
        groupHeading: (state) => {
          return cn(
            'px-4 py-4 text-sm font-semibold text-charcoal border-b-2 border-charcoal w-full last:border-b-0',
          );
        },
        noOptionsMessage: (state) => 'text-center  px-4 py-2',
        singleValue: (state) => {
          const tone = state.data?.hex;

          return cn(
            tone ? 'w-max text-base leading-none font-normal' : 'font-normal',
          );
        },
        multiValue: (state) => {
          const color = state.data?.hex;

          return cn(
            'text-charcoal px-1 py-1 rounded-full text-sm font-medium leading-none border-4 border-primary/0',
            color ? '' : 'bg-primary/50',
          );
        },
        multiValueRemove: (state) => 'cursor-pointer ml-2',
        dropdownIndicator: (state) =>
          cn(
            state.hasValue &&
              state.selectProps.id !== 'hasIndicator' &&
              '!hidden',
          ),
        clearIndicator: (state) => 'text-charcoal',
      }}
      styles={{
        multiValue: (provided, state) => {
          const color = state.data?.hex;

          return {
            ...provided,
            border: `2px solid ${color}`,
          };
        },
      }}
      components={
        isGroup
          ? {
              GroupHeading: HideGroupHeading,
              MenuList: HideGroupMenuList,
            }
          : {}
      }
      placeholder={placeholder}
      options={options}
      unstyled={true}
      isMulti={isMulti}
      onChange={onChange}
      {...props}
    />
  );
};
