import { gql } from '@apollo/client';

export const ME = gql`
  query Me {
    me {
      id
      email
      fullName
      role
      organizationRole
      isEmailConfirmed
      organization {
        name
        id
        featureFlags
      }
      selectedOrganization {
        name
        id
        featureFlags
      }
      currentStudioPlan {
        featureFlags {
          isPrivateImageEnabled
        }
      }
    }
  }
`;
