import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { ME } from '@/api/authentication';
import { TFashion } from '@/assets/svg';

export function AuthMiddleware({ children }) {
  const [me, { loading }] = useLazyQuery(ME);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      me();
    }
  }, [me]);

  if (loading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <TFashion />
      </div>
    );
  }

  return <>{children}</>;
}
