import React from 'react';
import { startCase } from 'lodash';

import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { apparelMapping } from '@/constants/index';
import { cn } from '@/helpers/utils';

import { ToneBar } from './DetailTones';

const colorFromIndex = (index) => {
  const colors = ['#68AAE3', '#E5EEFF', '#3EA67F', '#DEF2EB', '#C15F2A'];
  return colors[index % colors.length];
};

const renderToneItem = (item, index, isLast, percent) => (
  <ToneBar
    hex={item.tone?.hex}
    tone={item.tone}
    metric={item.percentage}
    isLast={isLast}
    key={index}
    variant="snapshot"
  />
);

const renderGenericItem = (item, index, isLast, hexGetter) => (
  <ToneBar
    hex={hexGetter(item, index)}
    tone={item}
    metric={item.percentage}
    isLast={isLast}
    key={index}
    variant="snapshot"
  />
);

const FeaturedImagesToolTip = ({ images }) => (
  <div className="flex flex-row items-start justify-start gap-x-1">
    {images.slice(0, 3).map((image, index) => (
      <div
        key={index}
        className={'aspect-[3/4] w-36 rounded'}
        style={{
          backgroundImage: `url(${image.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
    ))}
  </div>
);

const renderTableRow = (item, index, tabName, data) => {
  let content;
  const hasImages = item.featuredImages && item.featuredImages.length > 0;

  const renderContent = (label) => (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger>
          <div
            className={`py-1.5 text-sm font-medium text-black ${hasImages ? 'cursor-pointer' : 'cursor-auto'}`}
            onClick={() => console.log(item.featuredImages)}
          >
            {label}
          </div>
        </TooltipTrigger>
        {hasImages && (
          <TooltipContent sideOffset={4}>
            <FeaturedImagesToolTip images={item.featuredImages} />
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );

  switch (tabName) {
    case 'tone':
      content = (
        <div className="flex flex-row items-center justify-start">
          <div
            className="mr-2 h-2.5 w-2.5 rounded-full"
            style={{ background: item.tone ? item.tone.hex : '#000' }}
          />
          {renderContent(`Pantone ${item.tone ? item.tone.code : 'N/A'} TCX`)}
        </div>
      );
      break;
    case 'pattern':
      content = (
        <div className="flex flex-row items-center justify-start">
          <div
            className="mr-2 h-2.5 w-2.5 rounded-full"
            style={{ background: colorFromIndex(index) }}
          />
          {renderContent(startCase(item.ssiPattern))}
        </div>
      );
      break;
    case 'color':
      content = (
        <div className="flex flex-row items-center justify-start">
          <div
            className="h-2.5 w-2.5 rounded-full"
            style={{ background: item.ssiColor }}
          />
          {renderContent(startCase(item.ssiColor))}
        </div>
      );
      break;
    case 'apparel':
      content = renderContent(startCase(apparelMapping[item.apparel]));
      break;
    case 'attribute':
      content = renderContent(
        `${startCase(item.attributeKeyA || item.attributeKeyB)} - ${startCase(item.attributeValueA || item.attributeValueB)}`,
      );
      break;
    case 'fabric':
      content = renderContent(startCase(item.ssiFabric));
      break;
    default:
      content = null;
  }

  return (
    <TableRow key={index} className="border-none">
      <TableCell className="w-3/4 px-1 py-0">{content}</TableCell>
      <TableCell className="w-1/4 px-2 py-0 text-right font-medium">
        {item.percentage + '%'}
      </TableCell>
    </TableRow>
  );
};

export const DetailBreakDown = ({ tab }) => {
  const { data, name } = tab;
  const tabName = name.toLowerCase();

  if (!data || data.length === 0) {
    return null;
  }

  const displayData = [...data].slice(0, 5);

  const renderItems = () => {
    switch (tabName) {
      case 'tone':
        return displayData.map((item, index) =>
          renderToneItem(item, index, index === displayData.length - 1),
        );
      case 'color':
        return displayData.map((item, index) =>
          renderGenericItem(
            item,
            index,
            index === displayData.length - 1,
            (item) => item.ssiColor,
          ),
        );
      default:
        return displayData.map((item, index) =>
          renderGenericItem(
            item,
            index,
            index === displayData.length - 1,
            (_, i) => colorFromIndex(i),
          ),
        );
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-8">
      <div className={cn('flex w-full items-start')}>
        <div className="flex w-full flex-col">
          <h3 className="mb-4 text-lg font-bold leading-6 text-black">
            {tabName !== 'tone' ? name : 'Color ' + name} Distribution
          </h3>
          <div className="flex w-full flex-col items-start justify-center">
            <div className="flex w-full flex-row items-start justify-center">
              {renderItems()}
            </div>
            <Table className="w-full">
              <TableBody>
                {displayData.map((item, index) =>
                  renderTableRow(item, index, tabName, data),
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
