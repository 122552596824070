import { isBright } from '@/helpers/isBright';

import { SaveToCollection } from '../../../../../../../layouts/common/saveToCollection';
import { useCard } from '../../context';

import styles from './index.module.css';

export function ToneCard({ hex, code, name }) {
  const isBrightColor = isBright(hex);

  return (
    <div
      className={[
        styles.toneCard,
        isBrightColor ? 'text-charcoal' : 'text-white',
      ].join(' ')}
      style={{ backgroundColor: hex }}
    >
      <div>
        <h3>{name.replace('-', ' ')}</h3>
        <h3>Pantone TCX {code}</h3>
      </div>

      <SaveToCollection id={code} type="tone" />
    </div>
  );
}

export function ToneCards({ visible }) {
  const { data } = useCard();
  const tones = data.tones || [];

  if (!visible) return null;

  return (
    <div className={styles.toneCardsWrapper}>
      {tones.map((tone) => (
        <ToneCard {...tone} key={tone.hex} />
      ))}
    </div>
  );
}
