import React, { useState } from 'react';
import { LazyVideo } from 'react-lazy-media';
import { Bookmark, DiamondPlus, Grip, Heart, Replace } from 'lucide-react';

import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarButton } from './SidebarMenu';

export const ImagePreview = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className="flex gap-4">
      <div className="flex w-1/6 flex-col gap-4">
        {images.map((image, index) => (
          <TileImage
            key={index}
            imageUrl={image.url}
            variant="preview"
            isActive={image.alt === selectedImage.alt}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
      <div className="w-5/6">
        <img
          src={selectedImage.url}
          alt={selectedImage.alt || 'Selected image'}
          className="h-auto w-full rounded object-contain"
        />
      </div>
    </div>
  );
};

export const TileImage = ({
  imageUrl,
  title,
  description,
  alt,
  width,
  height,
  aspect = 'square',
  variant = 'default',
  isActive = false,
  onClick,
}) => {
  const { openTools, setOpenTools } = useStudioContext();

  if (variant === 'preview') {
    return (
      <div
        className={`relative cursor-pointer overflow-hidden rounded`}
        onClick={onClick}
      >
        <img src={imageUrl} alt={alt} className="h-auto w-full object-cover" />
        {isActive && <div className="absolute inset-0 bg-night/60"></div>}
      </div>
    );
  }

  return (
    <div className="group relative w-full cursor-pointer overflow-hidden rounded">
      <img
        src={imageUrl}
        alt={alt}
        className={`aspect-${aspect} h-full w-full scale-105 object-cover transition-all duration-300 group-hover:scale-110`}
      />
      <div className="absolute inset-0 bg-black/30 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
      <div className="absolute top-4 right-4 flex space-y-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="flex flex-col gap-2">
          <button className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
            <Heart size={20} />
          </button>
          <button className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
            <Bookmark size={20} />
          </button>
        </div>
      </div>
      <div className="absolute inset-x-4 bottom-4 flex justify-between opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="flex w-full flex-col gap-2 lg:flex-row">
          <button className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white px-2.5 py-1.5 text-center text-sm font-bold text-black hover:border-primary hover:bg-white hover:text-primary">
            <DiamondPlus size={16} />
            Variants
          </button>
          <button className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white px-2.5 py-1.5 text-center text-sm font-bold text-black hover:border-primary hover:bg-white hover:text-primary">
            <Replace size={16} />
            Upscale
          </button>
          <button
            onClick={() => setOpenTools(!openTools)}
            className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white px-2.5 py-1.5 text-center text-sm font-bold text-black hover:border-primary hover:bg-white hover:text-primary"
          >
            <Grip size={16} />
            Tools
          </button>
        </div>
      </div>
      {(title || description) && (
        <div className="absolute bottom-0 left-0 w-full px-4 py-2">
          {title && (
            <h3 className="text-base font-bold leading-5 text-white">
              {title}
            </h3>
          )}
          {description && (
            <p className="mt-1 text-sm text-white">{description}</p>
          )}
        </div>
      )}
    </div>
  );
};

export const ImageView = ({
  imageUrl,
  hoverImageUrl,
  title,
  description,
  alt,
  aspect = 'square',
}) => {
  return (
    <div className="group relative w-full cursor-pointer overflow-hidden rounded">
      <img
        src={imageUrl}
        alt={alt}
        className={`aspect-${aspect} h-full w-full scale-105 object-cover transition-all duration-300 group-hover:opacity-0`}
      />
      <img
        src={hoverImageUrl || imageUrl}
        alt={alt}
        className={`aspect-${aspect} absolute top-0 left-0 h-full w-full scale-105 object-cover opacity-0 transition-opacity duration-300 group-hover:opacity-100`}
      />
      {(title || description) && (
        <div className="absolute bottom-0 left-0 w-full translate-y-full px-4 py-2 transition-transform duration-300 group-hover:translate-y-0">
          {title && (
            <h3 className="text-base font-bold leading-5 text-white">
              {title}
            </h3>
          )}
          {description && (
            <p className="mt-1 text-sm text-white">{description}</p>
          )}
        </div>
      )}
      {title && (
        <div className="absolute bottom-2 left-4 text-white group-hover:opacity-0">
          <h3 className="text-base font-bold">{title}</h3>
        </div>
      )}
    </div>
  );
};

export const ImageCanvas = ({ imageUrl, alt }) => {
  return (
    <div className="relative h-full w-full overflow-hidden">
      <img src={imageUrl} alt={alt} className="h-full w-full object-cover" />
    </div>
  );
};

export const StudioHome = () => {
  const AIToolsContents = [
    {
      title: 'AI Prompt Helper',
      description:
        'Improve your prompts with word and phrase suggestions for better AI-generated outputs, all without language barriers.',
      image: '/assets/studio/studio-home/1.png',
      hoverImage: '/assets/studio/studio-home-hover/1.png',
      alt: 'AI Prompt Helper',
    },
    {
      title: 'Apply Pattern',
      description:
        'Effortlessly fit previously produced patterns on our platform to your current design.',
      image: '/assets/studio/studio-home/2.png',
      hoverImage: '/assets/studio/studio-home-hover/2.png',
      alt: 'Apply Pattern',
    },
    {
      title: 'Remove Background',
      description:
        'Remove image backgrounds for flexible use, whether created on our platform or uploaded.',
      image: '/assets/studio/studio-home/3.png',
      hoverImage: '/assets/studio/studio-home-hover/3.png',
      alt: 'Remove Background',
    },
    {
      title: 'Background Manipulation',
      description: 'Seamlessly Change and Customize Your Image Backdrops',
      image: '/assets/studio/studio-home/4.png',
      hoverImage: '/assets/studio/studio-home-hover/4.png',
      alt: 'Background Manipulation',
    },
    {
      title: 'Upscale',
      description:
        'Enhance the quality of your images with our AI-powered upscaling tool.',
      image: '/assets/studio/studio-home/5.png',
      hoverImage: '/assets/studio/studio-home-hover/5.png',
      alt: 'Upscale',
    },
    {
      title: 'Inpainting',
      description:
        'Effortlessly modify specific parts of your image based on color tones with our advanced AI tool.',
      image: '/assets/studio/studio-home/6.png',
      hoverImage: '/assets/studio/studio-home-hover/6.png',
      alt: 'Inpainting',
    },
    {
      title: 'Color Tone',
      description:
        'Easily add Pantone color tones to your images and modify specific parts with precise color adjustments.',
      image: '/assets/studio/studio-home/7.png',
      hoverImage: '/assets/studio/studio-home-hover/7.png',
      alt: 'Color Tone',
    },
    {
      title: 'Zoom In',
      description:
        'Generate zoomed versions of your images to capture and enhance intricate details with precision.',
      image: '/assets/studio/studio-home/8.png',
      hoverImage: '/assets/studio/studio-home-hover/8.png',
      alt: 'Zoom In',
    },
    {
      title: 'Zoom Out',
      description:
        'Create zoomed-out versions of your images to capture and emphasize the background effectively.',
      image: '/assets/studio/studio-home/9.png',
      hoverImage: '/assets/studio/studio-home-hover/9.png',
      alt: 'Zoom Out',
    },
    {
      title: 'Variants',
      description:
        'Generate new, similar images based on your uploaded image, offering alternative and diverse options.',
      image: '/assets/studio/studio-home/10.png',
      hoverImage: '/assets/studio/studio-home-hover/10.png',
      alt: 'Variants',
    },
    {
      title: 'Generate Tile',
      description:
        'Create a tiled version of your pattern for seamless continuity and a larger visual display.',
      image: '/assets/studio/studio-home/11.png',
      hoverImage: '/assets/studio/studio-home-hover/11.png',
      alt: 'Generate Tile',
    },
    {
      title: 'Image Manipulation',
      description:
        'View and customize the entire picture, including background and design, with a single prompt.',
      image: '/assets/studio/studio-home/12.png',
      hoverImage: '/assets/studio/studio-home-hover/12.png',
      alt: 'Image Manipulation',
    },
  ];

  return (
    <div className="flex flex-col pr-[11.375rem]">
      <div className="flex w-full flex-col gap-y-8">
        {/* Hero */}
        <div className="flex flex-col gap-y-4">
          <h1 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
            Home
          </h1>
          {/* Banner Video */}
          <div className="relative flex flex-col items-center justify-center">
            <LazyVideo
              src={'/assets/studio/studio-banner.mp4'}
              controls={false}
              loop={true}
              muted={true}
              autoplay={true}
              poster={'/assets/studio/hero-poster.png'}
              playsInline={true}
              classes={['aspect-[3.4/1] w-full h-full object-cover rounded']}
            />
            <div className="absolute top-0 left-0 flex flex-col p-6">
              <h1 className="text-xl font-medium text-white sm:text-5xl sm:leading-[5rem]">
                Design Without Limits
              </h1>
              <h2 className="text-base font-normal text-white sm:text-2xl sm:leading-[2rem]">
                AI-Powered Creativity at Your Fingertips
              </h2>
            </div>
          </div>
        </div>
        {/* Design Content */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Design with T-fashion
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Lorem ipsum dolor sit amet consectetur. Malesuada vestibulum
              accumsan et auctor diam. Magna sed semper cursus tellus
              ullamcorper tellus dis diam hendrerit. Gravida ultrices lectus
              orci massa risus eu et ac lectus. Congue bibendum eleifend vel
              orci enim risus amet consequat tempor.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-12">
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create an Apparel
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Design your apparel with our AI tool. Just provide a
                  description or reference image.
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-apparel.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded py-2 font-bold hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => console.log('Create an Apparel')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create a Pattern
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Make your own patterns with a prompt or images, and let our AI
                  create designs for you.
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-pattern.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded py-2 font-bold hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => console.log('Create a Pattern')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create from Sketch
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Upload your sketch and choose from a variety of patterns to
                  create a polished design.
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-sketch.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded py-2 font-bold hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => console.log('Create from Sketch')}
              />
            </div>
          </div>
        </div>
        {/* AI Tools Content */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              AI Tools
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Lorem ipsum dolor sit amet consectetur. Malesuada vestibulum
              accumsan et auctor diam. Magna sed semper cursus tellus
              ullamcorper tellus dis diam hendrerit. Gravida ultrices lectus
              orci massa risus eu et ac lectus. Congue bibendum eleifend vel
              orci enim risus amet consequat tempor.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {AIToolsContents.map((tool, index) => {
              return (
                <ImageView
                  imageUrl={tool.image}
                  hoverImageUrl={tool.hoverImage}
                  title={tool.title}
                  description={tool.description}
                  alt={tool.alt}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        {/* Tutorials */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Tutorials
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Lorem ipsum dolor sit amet consectetur. Malesuada vestibulum
              accumsan et auctor diam. Magna sed semper cursus tellus
              ullamcorper tellus dis diam hendrerit. Gravida ultrices lectus
              orci massa risus eu et ac lectus. Congue bibendum eleifend vel
              orci enim risus amet consequat tempor.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {AIToolsContents.slice(6, 9).map((tool, index) => {
              return (
                <ImageView
                  imageUrl={tool.image}
                  aspect="[4/3]"
                  hoverImageUrl={tool.hoverImage}
                  description={tool.description}
                  alt={tool.alt}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        {/* Inspiration */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Discover & Inspiration
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Lorem ipsum dolor sit amet consectetur. Malesuada vestibulum
              accumsan et auctor diam. Magna sed semper cursus tellus
              ullamcorper tellus dis diam hendrerit. Gravida ultrices lectus
              orci massa risus eu et ac lectus. Congue bibendum eleifend vel
              orci enim risus amet consequat tempor.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {AIToolsContents.slice(0, 6).map((tool, index) => {
              return (
                <ImageView
                  imageUrl={tool.image}
                  aspect="[3/4]"
                  hoverImageUrl={tool.hoverImage}
                  description={tool.description}
                  alt={tool.alt}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
