import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { ME } from '@/api/authentication';
import { useDemoAccount } from '@/api/authentication/hook';
import { EXPLORE } from '@/api/explore';
import { FlexRow, LikeButton } from '@/components';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { useExploreContext } from '@/contexts/ExploreContext';
import { SaveToCollection } from '@/layouts/common/saveToCollection';

import { ReportButton } from './components/ReportButton';
import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card } from './components';

export const SocialMediaImages = () => {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [cursorNext, setCursorNext] = useState(null);

  const { data: meData } = useQuery(ME);

  const [showDemoPopup, setShowDemoPopup] = useState(false);

  const { isDemoAccount } = useDemoAccount();

  const { hasBannedApparel, setHasBannedApparel } = useExploreContext();

  const moderationEnabled =
    meData?.me?.role === 'admin' || meData?.me?.role === 'moderator';

  const {
    selectedSocialMediaColor: selectedTrendsColors,
    selectedSocialMediaCategory: selectedTrendsCategory,
    selectedSocialMediaTimeframe: selectedSeason,
    selectedSocialMediaAudience: selectedAudience,
    selectedSocialMediaTone: selectedTone,
    socialMediaFilters,
    selectedSocialMediaPersonCount: selectedPersonCount,
    selectedSocialMediaPersonPosition: selectedPersonPosition,
    socialMediaSorting: selectedSorting,
  } = useExploreContext();

  const selectedAudienceId = selectedAudience;
  const selectedMarketId = selectedAudience?.marketId;

  // Memoize filters to avoid unnecessary recalculations
  const memoizedApparelFilter = useMemo(() => {
    return selectedTrendsCategory
      ? { value: selectedTrendsCategory.value }
      : null;
  }, [selectedTrendsCategory]);
  const memoizedColorFilter = useMemo(() => {
    return selectedTrendsColors?.length
      ? selectedTrendsColors.map((c) => c.value)
      : null;
  }, [selectedTrendsColors]);

  const { setPosts } = useExploreContext();

  const variables = {
    cursor: null,
    filters: {
      apparels: memoizedApparelFilter,
      colors: memoizedColorFilter,
      patterns: null, // patternFilter,
      season: selectedSeason?.label?.toLowerCase() || null,
      tone: null,
      attributes: null,
      // selectedTone
      // ? {
      //     code: selectedTone.value,
      //     similarity: "1"
      //   }
      // : null
      personCount: null,
      personPosition: null,
    },
    audiences: selectedAudienceId && [selectedAudienceId],
    market: selectedMarketId
      ? !['demo_market_1', 'demo_market_2'].includes(selectedMarketId)
        ? selectedMarketId
        : null
      : null,
    platform: socialMediaFilters.platform.value?.value,
    sorting: {
      sortBy: 'takenAt',
      sortOrder: selectedSorting?.value || null,
    },
    searchQuery: socialMediaFilters.search?.value,
  };

  const [prevVariables, setPrevVariables] = useState(JSON.stringify(variables));

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(EXPLORE, {
    variables,
    onCompleted: (newData) => {
      setPosts(newData?.explore?.data);
      if (newData?.explore?.cursor === null) {
        setFullyLoaded(true);
      } else {
        setCursorNext(newData.explore.cursor);
      }
      hasBannedApparel === true && setHasBannedApparel(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useMemo(() => debounce(refetch, 300), [refetch]);

  useEffect(() => {
    if (hasBannedApparel) {
      debouncedRefetch();
    }
  }, [hasBannedApparel, debouncedRefetch]);

  useEffect(() => {
    const attributes = Object.entries(socialMediaFilters)
      .map(([key, val]) => {
        if (key === 'attribute.custom') {
          return val?.value?.map((v) => {
            return {
              name: v?.name,
              value: v?.value,
            };
          });
        }

        return false;
      })
      .filter(Boolean);

    const newVariables = {
      cursor: null,
      filters: {
        apparels: memoizedApparelFilter,
        colors: memoizedColorFilter,
        patterns: null,
        season: selectedSeason?.label?.toLowerCase() || null,
        tone:
          selectedTone !== null
            ? {
                code: selectedTone?.value,
                similarity: 3,
              }
            : null,
        attributes:
          attributes.length > 0
            ? attributes[0].length === 0
              ? null
              : attributes[0]
            : null,
        personCount: selectedPersonCount?.value,
        personPosition: selectedPersonPosition?.value,
      },
      audiences: selectedAudienceId && [selectedAudienceId],
      market: selectedMarketId
        ? !['demo_market_1', 'demo_market_2'].includes(selectedMarketId)
          ? selectedMarketId
          : null
        : null,
      platform: socialMediaFilters.platform.value?.value,
      searchQuery: socialMediaFilters.search.value,
    };

    if (selectedSorting?.value) {
      const [sortBy, sortOrder] = selectedSorting.value.split('.');
      newVariables.sorting = {
        sortBy,
        sortOrder,
      };
    }

    if (JSON.stringify(newVariables) !== prevVariables) {
      debouncedRefetch(newVariables);
      setPrevVariables(JSON.stringify(newVariables));
    }
  }, [
    selectedAudienceId,
    selectedMarketId,
    selectedSeason,
    memoizedApparelFilter,
    memoizedColorFilter,
    socialMediaFilters,
    prevVariables,
    debouncedRefetch,
    selectedPersonCount,
    selectedPersonPosition,
    selectedSorting,
    selectedTone,
  ]);

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.refetch ||
    !data?.explore
  ) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    data.explore.hasNextPage &&
    fullyLoaded === false;

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <DemoAccountModal
        isOpen={showDemoPopup}
        onClose={() => setShowDemoPopup(false)}
      />
      <div>
        {data?.explore?.data?.length === 0 && <NoSuchResultsWereFound />}

        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
          {data?.explore?.data?.map((exploreItem, index) => (
            <div key={'explore_' + index} className="group">
              <Card.Root
                data={exploreItem}
                moderationEnabled={moderationEnabled}
                isCarousel={exploreItem.post?.children?.length > 1}
              >
                <Card.Image />

                <Card.HoverContent>
                  <FlexRow justify="between" items="center">
                    <Card.Tones />

                    <FlexRow
                      className="self-end"
                      justify="between"
                      items="center"
                    >
                      <ReportButton id={exploreItem?.post?.id} />
                      <SaveToCollection
                        id={exploreItem?.post?.id}
                        collections={exploreItem?.collections}
                        type="post"
                      />

                      {/* <More /> */}

                      <LikeButton
                        item={exploreItem?.post?.id}
                        isLiked={exploreItem?.isLiked}
                        type="post"
                      />
                    </FlexRow>
                  </FlexRow>
                  <Card.Footer
                    id={exploreItem.post?.id}
                    exploreItemId={exploreItem?.id}
                    post={exploreItem?.post}
                    demoPopupClick={
                      isDemoAccount ? () => setShowDemoPopup(true) : null
                    }
                  />
                </Card.HoverContent>
              </Card.Root>
            </div>
          ))}
          {isLoadingMore &&
            Array.from({ length: 20 }).map((_, i) => (
              <div className="ghost aspect-[2/3] w-full" key={i}></div>
            ))}
          {renderLazyLoad &&
            Array(20)
              .fill()
              .map((_, index) => (
                <div className="h-16 w-full" key={index}>
                  <LazyLoadTrigger
                    key={'lazyload_' + index}
                    fetchMore={fetchMore}
                    loading={networkStatus === NetworkStatus.loading}
                    cursorNext={cursorNext}
                  />
                </div>
              ))}
        </div>
      </div>
    </>
  );
};
