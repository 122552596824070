import { gql } from '@apollo/client';

export const COLLECTIONS = gql`
  query Collections($cursor: String, $includeOrganizationViewable: Boolean) {
    collections(
      cursor: $cursor
      includeOrganizationViewable: $includeOrganizationViewable
    ) {
      data {
        id
        name
        itemsCount
        featuredImages {
          url
        }
        owner {
          fullName
        }
        updatedAt
        items {
          type
          trendItemId
          postId
          tone
          id
        }
      }
      cursor
    }
  }
`;

export const COLLECTION = gql`
  query Collection($id: ID!) {
    collection(id: $id) {
      id
      name
      access
      owner {
        id
      }
    }
  }
`;

export const GET_COLLECTION_ITEMS_MERGED = gql`
  query GetCollectionItems($collection: ID!, $cursor: String) {
    collectionItemsMerged(collection: $collection, cursor: $cursor) {
      cursor
      data {
        id
        item {
          ... on ExploreFashionWeekItem {
            id
            image {
              url
              width
              height
              blurhash
            }
            season
            fashionWeekSlug
            city
            category
            apparel
            itemType
          }
          ... on StudioItem {
            image {
              url
              width
              height
              blurhash
            }
            createdBy {
              fullName
            }
          }
          ... on Tone {
            name
            code
            hex
            main
          }
          ... on Post {
            id
            image {
              url
              width
              height
              credit
              designer
              blurhash
            }
          }
          ... on TrendItem {
            id
            image {
              url
            }
            market {
              id
            }
            tiType
            tone {
              hex
            }
            title
            optimalLaunch
            reach
            trendType
            yoyGrowth {
              percent
              data {
                full
                green
              }
            }
          }
        }
        addedBy {
          fullName
        }
      }
    }
  }
`;

export const GET_COLLECTION_ITEMS_GROUPED = gql`
  query GetCollectionItems($collection: ID!, $cursor: String) {
    collectionItemsGrouped(collection: $collection) {
      tones(cursor: $cursor) {
        data {
          item {
            id
            name
            code
            hex
            main
          }
          addedBy {
            fullName
          }
        }
        cursor
      }
      images(cursor: $cursor) {
        data {
          item {
            ... on Post {
              image {
                url
                width
                height
                credit
                designer
                blurhash
              }
              id
            }
            ... on ExploreFashionWeekItem {
              image {
                url
                width
                height
                blurhash
              }
              id
            }
            ... on StudioItem {
              image {
                url
                width
                height
                blurhash
              }
              id
            }
          }
          addedBy {
            fullName
          }
        }
        cursor
      }
      trendItems(cursor: $cursor) {
        data {
          item {
            id
            tiType
            tone {
              hex
            }
            image {
              url
            }
            market {
              id
            }
            title
            optimalLaunch
            reach
            trendType
            yoyGrowth {
              percent
              data {
                full
                green
              }
            }
          }
          addedBy {
            fullName
          }
        }
        cursor
      }
    }
  }
`;

export const REMOVED_EXPLORE_ITEM_FRAGMENT = gql`
  fragment RemovedExploreItem on ExploreItem {
    removed
  }
`;
