import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { useUser } from '@/api/authentication/hook';
import { useTrendsQuery } from '@/api/trends/hook';
import {
  Button,
  FlexRow,
  LikeButton,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from '@/components';
import { Modal } from '@/components/Modal';
import { MetricPill } from '@/components/Pills';
import SeeMore from '@/components/SeeMore';
import { TrendsBackground } from '@/components/Trends/TrendsBackground';
import { Text } from '@/components/Typography/index';
import { reachMapping, seasons, trendTypeMapping } from '@/constants';
import { colorMap, trendItemTypeMapping } from '@/constants/index';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useExploreContext } from '@/contexts/ExploreContext';
import { useTrendsContext } from '@/contexts/TrendsContext';
import { cn, stringFormatter } from '@/helpers/utils';
import { useTrends } from '@/hooks/trends';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Icon } from '@/icons';
import { SaveToCollection } from '@/layouts/common';
import { Card } from '@/pages/general/explore/components';
import { Watermark } from '@/pages/general/explore/components/Card/components/Image';
import { ReportButton } from '@/pages/general/explore/components/ReportButton';
import { Comments } from '@/pages/general/imagePreview/components/Comments';
import {
  OptimalLaunch,
  Reach,
  YoyGrowth,
} from '@/pages/general/trends/components';
import tones from '@/tones.json';

import { TrendsCard } from '../../components/Card';
import { EditGallery } from '../../components/EditGallery';

import { DetailTones } from './components/DetailTones';
import { Attributes, Forecast, Hero } from './components';

dayjs.extend(relativeTime);

function AttributeColorizedPill({ title, value }) {
  let color = 'primary';
  let isMagnitude = false;
  const magnitudeColor = Object.values(reachMapping).find(
    (item) => item.text === value,
  )?.color;
  if (title === 'Magnitude') {
    isMagnitude = true;
  }
  if (title === 'Yoy Growth') {
    if (value === undefined) {
      value = '0%';
    }
    if (Number(value) < 0) {
      value = value + '%';
      color = 'red-500';
    } else if (Number(value) >= 0) {
      value = '+' + value + '%';
      color = 'green';
    }
  }
  const uppercase = true;
  const borderColor = 'border-' + color;
  const bgColor = 'bg-' + color;
  const textColor = 'text-' + color;
  const bgOpposite = bgColor + ' bg-opalocationName-10';
  return (
    <div
      style={isMagnitude ? { borderColor: magnitudeColor } : {}}
      className={`flex flex-row items-center justify-between rounded-full border shadow ${!isMagnitude && borderColor}`}
    >
      <div
        style={isMagnitude ? { background: magnitudeColor } : {}}
        className={`w-full rounded-l-full px-[0.8rem] py-[0.625rem] text-center ${!isMagnitude && bgColor}`}
      >
        <p className="truncate text-sm font-medium uppercase leading-[0.875rem] text-white">
          {title}
        </p>
      </div>
      <div
        style={isMagnitude ? { background: magnitudeColor + '1A' } : {}}
        className={`w-full rounded-r-full px-[0.8rem] py-[0.625rem] text-center ${!isMagnitude && bgOpposite}`}
      >
        <p
          style={isMagnitude ? { color: magnitudeColor } : {}}
          className={`truncate text-sm font-medium leading-[0.875rem] ${uppercase ? 'uppercase' : ''} ${!isMagnitude && textColor}`}
        >
          {value}
        </p>
      </div>
    </div>
  );
}

function FeaturedImageSkeleton() {
  return (
    <div className="ghost flex h-[480px] w-[300px] flex-col justify-items-start gap-y-[1rem] " />
  );
}

// function FeaturedImage({ item: image }) {
//   const item = image ? { ...image } : null;
//   const [dimensions, { loading, error }] = useImageSize(item.url);

//   if (loading) return <div></div>;
//   if (error) return <div></div>;

//   // debugger

//   if (!item || !dimensions) return <></>;

//   if (!item?.width) item.width = dimensions.width;
//   if (!item?.height) item.height = dimensions.height;

//   return (
//     <div
//       className="flex flex-col justify-items-start gap-y-[1rem] rounded-md"
//       style={{
//         background: '#F5F5F5',
//         // width: (item.width / item.height) * 14 + 'rem'
//       }}
//     >
//       <img
//         src={item.url}
//         className={'h-full rounded-md border-0 border-white'}
//       />
//     </div>
//   );
// }

function FeaturedImages({ images, skeleton, filter }) {
  const columnsCountBreakPoints = {
    350: 2,
    1024: 3,
    1280: 4,
    1600: 5,
    1920: 6,
  };

  const hasPost = images.map((item) => !!item.entity.influencer);

  const { data: meData } = useUser();

  const moderationEnabled =
    meData?.me?.role === 'admin' || meData?.me?.role === 'moderator';

  const navigate = useNavigate();

  const { setActiveTab } = useExploreContext();

  const handleViewMoreButton = () => {
    !hasPost ? filter('fw') : filter('sm');
    !hasPost ? setActiveTab('fashion-weeks') : setActiveTab('social-media');
    navigate('/explore');
  };

  const renderImages = () =>
    skeleton
      ? Array.from({ length: 8 }).map((_, index) => (
          <FeaturedImageSkeleton key={index} />
        ))
      : images.map((item, index) => (
          <Card.Root
            data={item.entity}
            key={'explore_' + index}
            moderationEnabled={moderationEnabled}
          >
            <Card.Image />
            {!hasPost ? (
              <Card.HoverContent>
                <FlexRow justify="between" items="center">
                  <Card.Tones />

                  <FlexRow
                    className="self-end"
                    justify="between"
                    items="center"
                  >
                    <ReportButton id={item?.entity?.influencer?.id} />
                    <SaveToCollection
                      id={item.entity?.post?.id}
                      collections={item?.entity?.collections}
                      type="post"
                    />

                    {/* <More /> */}

                    <LikeButton
                      item={item.entity?.post?.id}
                      isLiked={item.entity?.post?.isLiked}
                      type="post"
                    />
                  </FlexRow>
                </FlexRow>
                <Card.Footer
                  id={item.entity?.post?.id}
                  activeTab={'social-media'}
                />
              </Card.HoverContent>
            ) : (
              <Card.HoverContent gradientDegree={'0deg'}>
                <div style={{ zIndex: 50 }}>
                  <FlexRow justify="self-end" items="end">
                    <Watermark />
                  </FlexRow>
                </div>
                <Card.Footer id={item.entity?.id} activeTab={'fashion-weeks'} />
              </Card.HoverContent>
            )}
          </Card.Root>
        ));

  return (
    <div className="relative w-full">
      {/* Blurred overlay div */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-md"></div>

      {/* Image grid */}
      <div className="relative">
        <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
          <Masonry gutter={'1.625rem'}>{renderImages()}</Masonry>
        </ResponsiveMasonry>
      </div>

      {/* "View More" button */}
      <div className="absolute bottom-0 left-1/2 z-10 flex h-96 w-full -translate-x-1/2 items-center justify-center">
        <Button
          onClick={handleViewMoreButton}
          className="relative top-20 z-20 rounded-full border-2 border-primary bg-primary/80 px-10 py-4 text-white"
        >
          <Text
            className="text-2xl [text-shadow:_0_4px_8px_rgb(0_0_0_/_30%)]"
            style={{
              fontWeight: 500,
            }}
          >
            View More in Explore
          </Text>
        </Button>
        <div className="absolute inset-0 top-72 block h-24 bg-gradient-to-t from-white via-white/70 to-white/0"></div>
      </div>
    </div>
  );
}

function Wrapper({ children, imagePreview = true }) {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    function handleSizeChange() {
      const navbarEl = document.getElementById('main-nav');
      const bodyEl = document.querySelector('body');

      setWrapperHeight(bodyEl.offsetHeight - navbarEl.offsetHeight);
    }

    handleSizeChange();

    window.addEventListener('resize', handleSizeChange);

    return () => window.removeEventListener('resize', handleSizeChange);
  }, []);

  return (
    <div
      className={cn(
        'absolute right-0 left-0 z-[500] grid animate-fade-in overflow-auto bg-white',
        imagePreview ? 'grid-cols-[67%_33%]' : 'grid-cols-[33%_67%]',
      )}
      style={{
        height: `${wrapperHeight}px`,
      }}
    >
      {children}
    </div>
  );
}

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-x-2 text-2xl font-semibold">
        Trend Insights Analysis
      </div>
      <RxCross1
        size={'2rem'}
        onClick={() => {
          if (location.key === 'default') {
            navigate('/overview');
          } else {
            navigate(-1);
          }
        }}
        className="cursor-pointer"
      />
    </div>
  );
}

export const TrendItemDetail = () => {
  const [showModal, setShowModal] = useState(false);
  // const [currentAudienceId, setCurrentAudienceId] = useState(null);

  const { itemId, marketId: market, seasonId } = useParams();

  const selectedMarketId = market;

  const { moderationEnabled } = useUser();

  const marketId = selectedMarketId || market;

  const { setAvailableYears } = useTrends();

  const { data, loading, error } = useTrendsQuery('trendItem', {
    variables: {
      trendItemId: itemId,
      season: seasonId,
      market: marketId,
    },
    onCompleted: (data) => {
      setAvailableYears(data?.trendItem?.forecast?.availableYears || []);
      // if (data?.trendItem?.audience) {
      //   setCurrentAudienceId(data?.trendItem?.audience?.id);
      // }
    },
  });

  // useEffect(() => {
  //   if (currentAudienceId && filters?.source?.options) {
  //     if (filters?.source?.value?.value === currentAudienceId) return;

  //     const audience = filters?.source?.options
  //       .map((option) => option?.options || option)
  //       .flat()
  //       .find((audience) => audience.value === currentAudienceId);

  //     if (audience) {
  //       handleSourceOnChange(audience);
  //     }
  //   }
  // }, [currentAudienceId, filters, handleSourceOnChange]);

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  const tone = data?.trendItem.tone;

  return (
    <>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <EditGallery
          item={data?.trendItem}
          onClose={() => setShowModal(false)}
        />
      </Modal>
      <div
        className={`flex w-full flex-col ${
          !tone && !moderationEnabled ? 'py-[2.25rem]' : ''
        }`}
      >
        <Hero.Root tone={tone}>
          {!tone && <TrendsBackground />}

          <Hero.Header
            skeleton={loading}
            openGallery={() => setShowModal(true)}
            moderationEnabled
          />

          <Hero.Main>
            <Hero.Info>
              <OptimalLaunch.Root>
                <OptimalLaunch.Title tone={tone} skeleton={loading} />
                <OptimalLaunch.Divider tone={tone} skeleton={loading} />

                <OptimalLaunch.Value
                  tone={tone}
                  optimalLaunch={data?.trendItem?.optimalLaunch}
                  skeleton={loading}
                />
              </OptimalLaunch.Root>
              <Hero.Stats>
                <YoyGrowth.Root>
                  <YoyGrowth.Top bold skeleton={loading}>
                    {data?.trendItem?.yoyGrowth?.percent}
                  </YoyGrowth.Top>

                  <YoyGrowth.Chart
                    skeleton={loading}
                    data={data?.trendItem?.yoyGrowth?.data}
                    isDown={data?.trendItem?.yoyGrowth?.percent < 0}
                  />
                </YoyGrowth.Root>

                <Reach bold level={data?.trendItem?.reach}></Reach>
              </Hero.Stats>
            </Hero.Info>

            <Hero.FeaturedImages
              images={data?.trendItem?.featuredImages}
              skeleton={loading}
            />
          </Hero.Main>
        </Hero.Root>

        <Forecast.Root>
          <Forecast.Header.Root>
            <Forecast.Header.Title skeleton={loading}>
              {trendTypeMapping[data?.trendItem?.trendType]?.name ||
                'Rising Star'}

              {!loading ? (
                <PopoverRoot>
                  <PopoverTrigger>
                    <Icon name="help" />
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="flex w-[17.3125rem] flex-col px-[2rem] pt-[1.625rem]">
                      <div className="flex flex-col">
                        <div className="mb-[1.3125rem] flex flex-col">
                          <h5 className="text-[1rem] font-semibold leading-[1.155rem]  text-white">
                            {trendTypeMapping[data?.trendItem?.trendType]?.name}
                          </h5>
                          <p className="font-semi-light text-[1rem] leading-[1.155rem]  text-white">
                            {
                              trendTypeMapping[data?.trendItem?.trendType]
                                ?.definition
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </PopoverContent>
                </PopoverRoot>
              ) : null}
            </Forecast.Header.Title>
            <Forecast.Header.Subtitle skeleton={loading}>
              Trend Type
            </Forecast.Header.Subtitle>
            <Forecast.Header.Desc skeleton={loading}>
              {trendTypeMapping[data?.trendItem?.trendType]?.definition ||
                'This trend type refers to a product that is experiencing rapid growth in popularity over time. This type of trend may be driven by factors such as a celebrity endorsement or a viral social media posts. Brands that are able to identify and capitalize on rising stars earlier on can experience significant growth in revenue and market share.'}
            </Forecast.Header.Desc>
          </Forecast.Header.Root>

          <Forecast.Chart />
        </Forecast.Root>

        {data?.trendItem?.attributes.length ? (
          <Attributes data={data?.trendItem?.attributes} />
        ) : null}

        <Tabs.Root>
          <Tabs.Navigation />
          <Tabs.Switch />
        </Tabs.Root>
        {/* <Charts data={data?.trendItem?.charts} /> */}
      </div>
    </>
  );
};

export function Detail() {
  const [editCoverState, setEditCoverState] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const { selectedAudience } = useAppFilters();
  const { marketsData } = useTrendsContext();

  const { setAvailableYears } = useTrends();

  const { moderationEnabled } = useUser();

  const navigate = useNavigate();
  const location = useLocation();

  const { itemId, marketId: market, seasonId } = useParams();

  const selectedMarketId = selectedAudience?.marketId;

  const marketId = selectedMarketId || market;

  const { handleSocialMediaFiltersOnChange, handleSocialMediaSortOnChange } =
    useExploreContext();

  const { isEnabled: isSnapshotTotalQualityEngagementEnabled } = useFeatureFlag(
    FEATURE_FLAGS.TOTAL_ENGAGEMENT,
  );

  const { data: similarData } = useTrendsQuery('similarTrendItems', {
    variables: {
      itemId,
      season: seasonId,
    },
  });

  useEffect(() => {
    function closePage(e) {
      if (e.key === 'Escape') {
        if (location.key === 'default') {
          return navigate('/overview');
        } else {
          return navigate(-1);
        }
      }
      return null;
    }

    window.addEventListener('keydown', closePage);

    return () => window.removeEventListener('keydown', closePage);
  }, []);

  const { data, loading, error } = useTrendsQuery('trendItem', {
    variables: {
      trendItemId: itemId,
      season: seasonId,
      market: marketId,
    },
    onCompleted: (data) => {
      setAvailableYears(data?.trendItem?.forecast?.availableYears || []);
    },
  });

  // useEffect(() => {
  //   if (currentAudienceId && filters?.source?.options) {
  //     if (filters?.source?.value?.value === currentAudienceId) return;

  //     const audience = filters?.source?.options
  //       .map((option) => option?.options || option)
  //       .flat()
  //       .find((audience) => audience.value === currentAudienceId);

  //     if (audience) {
  //       handleSourceOnChange(audience);
  //     }
  //   }
  // }, [currentAudienceId, filters, handleSourceOnChange]);

  useEffect(() => {
    if (marketsData) {
      const market = marketsData.markets[0];
      setCategoryList(market.apparels);
    }
  }, [marketsData]);

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="absolute inset-x-0 z-[500] grid h-full animate-fade-in grid-cols-[67%_33%] overflow-hidden bg-white">
        <div className="flex h-full w-full flex-col overflow-hidden px-[3.75rem] py-8">
          <div className="grid grid-cols-3 gap-8 lg:grid-cols-5">
            {Array.from({ length: 20 }).map((_, index) => (
              <div
                key={index}
                className="ghost flex aspect-[7/11] flex-col justify-items-start gap-y-[1rem] rounded-md"
              />
            ))}
          </div>
        </div>
        <div className="flex h-full w-full flex-col justify-between overflow-hidden border-l border-secondary py-9 px-[3.75rem]">
          <div className="flex flex-col gap-y-8">
            <div className="ghost flex h-12 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-16 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-20 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-28 flex-wrap items-center justify-start gap-4"></div>
          </div>
          <div className="flex flex-col gap-y-8">
            <div className="ghost flex h-12 flex-col gap-y-4"></div>
            <div className="ghost flex h-12 flex-col gap-y-4"></div>
          </div>
        </div>
      </div>
    );
  }

  const trendItem = data?.trendItem;
  const selectedMarket = trendItem.market?.id;

  const foundAudience = marketsData.markets.find(
    (market) => market.id === selectedMarket,
  );

  const gender = trendItem?.audience?.gender === '1' ? 'Men' : 'Women';
  const ageMin =
    trendItem?.audience?.ageMin || foundAudience.audiences?.[0]?.ageMin;
  const ageMax =
    trendItem?.audience?.ageMax || foundAudience.audiences?.[0]?.ageMax;
  const locationName = trendItem?.market?.location?.name || 'London';
  const yoyGrowth = trendItem?.yoyGrowth?.percent.toFixed(0);
  const tiType = trendItem?.tiType;
  const filterType = trendItemTypeMapping[tiType];
  const tiTone = tones.find((item) => item.value === trendItem?.tiColorTone);
  const colorHex = colorMap[trendItem?.tiColor];
  const optLaunch = trendItem?.optimalLaunch
    ? dayjs(trendItem.optimalLaunch).format('MMMM')
    : 'MAY';
  let reach = trendItem?.reach;
  reach = reachMapping[reach]?.text || 'Massive';
  let trendType = trendItem?.trendType;
  trendType = trendTypeMapping[trendType]?.name || 'Rising Star';
  let season = seasonId;
  season = seasons[season]?.label || 'Spring';

  const categoryFilter = (() => {
    if (categoryList.length === 0) {
      return { label: '', value: '' };
    }

    const selectedCategory = categoryList.find(
      (item) => item.value === trendItem?.apparel,
    );

    if (!selectedCategory) {
      return { label: '', value: '' };
    }

    const { label, value } = selectedCategory;
    const formattedLabel =
      label === 'pant/trouser' ? 'Pant Trouser' : stringFormatter(label);

    return {
      label: formattedLabel,
      value: value.toString() || '',
    };
  })();

  const audienceFilter = {
    value: foundAudience.audiences[0].groups?.[0]?.audienceId,
  };

  const marketFilter = {
    label: gender + ', ' + ageMin + '-' + ageMax,
    location: locationName,
    value: selectedMarket,
  };

  const platformFilter = {
    label: foundAudience.audiences[0].groups?.[0]?.platform.toUpperCase(),
    value: foundAudience.audiences[0].groups?.[0]?.platform.toUpperCase(),
  };

  const colorFilter = {
    hex: colorHex,
    label: stringFormatter(trendItem?.tiColor),
    value: trendItem?.tiColor,
  };

  const toneFilter = {
    hex: tiTone?.hex,
    label: tiTone?.label,
    value: tiTone?.value,
  };

  const sorting = {
    label: 'Published at (Desc)',
    value: 'takenAt.desc',
  };

  const handleExploreFilter = (type) => {
    // Mapping filter types to their handlers
    const filterHandlers = {
      apparelLabelId: { filter: categoryFilter, key: 'category' },
      'tones.main': { filter: colorFilter, key: 'color' },
      'tones.code': { filter: toneFilter, key: 'tone' },
    };

    // General filter handling function
    const handleFilterChange = (filter, key) => {
      if (type === 'sm') {
        if (key !== 'color') {
          return handleSocialMediaFiltersOnChange(filter, key);
        } else {
          return handleSocialMediaFiltersOnChange([filter], key);
        }
      }
    };

    handleFilterChange(marketFilter, 'market');
    handleFilterChange(audienceFilter, 'audience');
    handleFilterChange(platformFilter, 'platform');
    handleSocialMediaSortOnChange(sorting, 'sort');

    // Iterate over filterType and apply the corresponding handler
    filterType.forEach((item) => {
      const handler = filterHandlers[item];
      if (handler) {
        handleFilterChange(handler.filter, handler.key);
      } else {
        console.warn(`Unhandled filter type: ${item}`);
      }
    });
  };

  return (
    <Wrapper>
      <div className="flex w-full flex-col gap-y-6 overflow-auto px-[3.75rem] py-8">
        {editCoverState && moderationEnabled && (
          <Modal
            isOpen={editCoverState}
            onClose={() => setEditCoverState(false)}
          >
            <EditGallery
              item={trendItem}
              onClose={() => setEditCoverState(false)}
            />
          </Modal>
        )}
        <div className="flex flex-col">
          <div className="flex flex-row items-start justify-between gap-4">
            <div className="flex flex-row items-end gap-4">
              <div>
                <h3 className="text-md font-semibold text-primary">
                  {season} Trends: {locationName || 'London'}, {ageMin || '20'}-
                  {ageMax || '35'}, {gender}
                </h3>
                <h2 className="text-4xl font-bold text-black">
                  {trendItem?.title || 'Black Shoe'}
                </h2>
              </div>
            </div>
            <div className="flex flex-row items-end">
              <SaveToCollection id={trendItem.tiId} type="trendItem">
                {(loading) => (
                  <div className="flex gap-x-2">
                    <Button
                      className={`${loading ? 'ghost' : ''} rounded-lg py-2 font-bold text-white`}
                    >
                      Save
                    </Button>
                    {moderationEnabled && (
                      <Button
                        className={`${loading ? 'ghost' : ''} rounded-lg py-2 font-bold text-white`}
                      >
                        Edit Gallery
                      </Button>
                    )}
                  </div>
                )}
              </SaveToCollection>
            </div>
          </div>
          {import.meta.env.VITE_APP_ENV === 'development' && (
            <div className="mt-4 flex flex-col items-center justify-start gap-4">
              <SeeMore
                text="The Black Loose Leather Jacket has emerged as the undisputed
                star of the fashion scene in London. Recording the highest
                visibility among all trending items, it has been embraced by a
                diverse array of influencers, signaling its universal appeal.
                The Black Loose Leather Jacket has emerged as the undisputed
                star of the fashion scene in London. Recording the highest
                visibility among all trending items, it has been embraced by a
                diverse array of influencers, signaling its universal appeal."
                maxLength={40}
              />
            </div>
          )}
        </div>
        {trendItem?.featuredImages && (
          <FeaturedImages
            images={trendItem?.featuredImages}
            skeleton={false}
            filter={handleExploreFilter}
          />
        )}
        {similarData?.similarTrendItems?.length > 0 && (
          <div className="flex flex-col gap-y-4">
            <h2 className="mt-8 text-center text-3xl font-bold text-black">
              Similar Trends
            </h2>
            <div className="my-4 grid grid-cols-3 gap-8">
              {similarData?.similarTrendItems.map((item, index) => (
                <TrendsCard key={index} variant="sm" trendItem={item} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between overflow-auto border-l border-secondary py-9 px-[3.75rem]">
        <div className="flex flex-col gap-y-8">
          <Header id={trendItem.tiId} edit={setEditCoverState} />
          <div className="flex flex-wrap items-center justify-start gap-4">
            <AttributeColorizedPill title="Trend Type" value={trendType} />
            <AttributeColorizedPill title="Yoy Growth" value={yoyGrowth} />
            <AttributeColorizedPill title="Magnitude" value={reach} />
            <AttributeColorizedPill title="Opt. Launch" value={optLaunch} />
          </div>
          {import.meta.env.VITE_APP_ENV === 'development' && (
            <div
              className={`grid ${isSnapshotTotalQualityEngagementEnabled === true ? 'grid-cols-3' : 'grid-cols-2'} gap-1`}
            >
              <MetricPill
                color="#C29B77"
                metric="Trend Type"
                metricValue="Rising Star"
              />
              <MetricPill
                metric="Visibility"
                metricChange="+3.5%"
                metricValue="70.2K"
                timePeriod={{ value: '30.5K', label: 'Last Week' }}
              />
              <MetricPill
                metric="Quality Engagement"
                metricChange="+3.5%"
                metricValue="70.2K"
                timePeriod={{ value: '30.5K', label: 'Last Week' }}
              />
              <MetricPill
                metric="Total Engagement"
                metricChange="+3.5%"
                metricValue="70.2K"
                timePeriod={{ value: '30.5K', label: 'Last Week' }}
              />
            </div>
          )}
          <div className="mb-8 w-full">
            <h4 className="mb-4 text-lg font-bold leading-5">Chart</h4>
            <Forecast.Root>
              <Forecast.Chart />
            </Forecast.Root>
          </div>
        </div>
        <div className="flex flex-col">
          <DetailTones
            tones={trendItem?.tabs?.length === 0 ? null : trendItem.tabs}
          />
          <Comments itemId={trendItem?.tiId} isTrendItem />
        </div>
      </div>
    </Wrapper>
  );
}
