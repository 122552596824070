import React from 'react';

import { FEATURE_FLAGS } from '@/hooks/useFeatureFlag';
import * as Pages from '@/pages/general';

import { BehindFeatureFlag } from './types/Private';
import { Private, TrendsRedirector } from './types';

export const trends = [
  {
    path: 'trends',
    element: (
      <Private>
        <TrendsRedirector />
      </Private>
    ),
  },
  {
    path: 'trends/:tab/:marketId/:audienceId',
    element: (
      <Private>
        <BehindFeatureFlag featureFlag={FEATURE_FLAGS.STUDIO}>
          <TrendsRedirector>
            <Pages.Trends />
          </TrendsRedirector>
        </BehindFeatureFlag>
      </Private>
    ),
  },
  {
    path: 'trends/:tab/detail/:marketId/:item/:itemId/:seasonId',
    element: (
      <Private>
        <Pages.Detail />
      </Private>
    ),
  },
  {
    path: 'trends/:tab/detail/:item/:itemId',
    element: (
      <Private>
        <Pages.SnapshotsDetail />
      </Private>
    ),
  },
  {
    path: 'overview',
    element: (
      <Private>
        <TrendsRedirector>
          <Pages.Overview />
        </TrendsRedirector>
      </Private>
    ),
  },
];
