import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import * as Tabs from '@radix-ui/react-tabs';

import { useTrendsQuery } from '@/api/trends/hook';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { TabHeading } from '@/components/TabHeading';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useTrendsContext } from '@/contexts/TrendsContext';

import { LazyLoadButton } from '../../explore/components/utils';

import { SnapsCard, TrendsCard, TrendTonesCard } from './Card';
import { TrendsMetricsChart } from './TrendsMetricsChart';

const tabs = [
  {
    label: 'Insights',
    value: 'insights',
    Component: TrendsInsights,
  },
  {
    label: 'Snapshots',
    value: 'snapshots',
    Component: TrendsSnapshots,
  },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: TrendsFashionWeeks,
  },
  {
    label: 'Brands',
    value: 'brands',
    Component: TrendsBrands,
  },
  {
    label: 'Buzzwords',
    value: 'buzzwords',
    Component: TrendsFashionWeeks,
  },
];

function TrendsInsights({
  items,
  tones,

  network,
  networkStatus,
  loading,
  loadingTones,
  fetchMore,
  fetchTones,
  cursorNext,
  tonesCursor,
}) {
  return (
    <div className="flex flex-col">
      {(loading || loadingTones) &&
      network !== NetworkStatus.fetchMore &&
      networkStatus !== NetworkStatus.fetchMore ? (
        <TrendsCardGhost variant="md" />
      ) : (
        <>
          <div className="flex flex-col">
            <h2 className="mt-6 text-3xl font-bold">Top Trends</h2>
            <div className="my-4 grid grid-cols-3 gap-8">
              {items?.map((item, index) => (
                <TrendsCard key={index} variant="md" trendItem={item} />
              ))}
              {network === NetworkStatus.fetchMore &&
                [...Array(6)].map((_, index) => <GhostItem key={index} />)}
            </div>
            <div className="flex items-center justify-center">
              <LazyLoadButton
                fetchMore={fetchMore}
                cursorNext={cursorNext}
                disabled={loading}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="mt-6 text-3xl font-bold">Top Tones</h2>
            <div className="my-4 grid grid-cols-3 gap-8">
              {tones?.map((tone, index) => (
                <TrendTonesCard key={index} variant="md" trendItem={tone} />
              ))}
              {networkStatus === NetworkStatus.fetchMore &&
                [...Array(6)].map((_, index) => <GhostItem key={index} />)}
            </div>
            <div className="flex items-center justify-center">
              <LazyLoadButton
                fetchMore={fetchTones}
                cursorNext={tonesCursor}
                hidden={tonesCursor === null}
                disabled={loadingTones}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function TrendsSnapshots({ snapshots, loading, network, variant, title }) {
  const [timeframeData, setTimeframeData] = useState('weeklyData');
  const { selectedTimeframe } = useAppFilters();

  useEffect(() => {
    if (selectedTimeframe) {
      setTimeframeData(
        selectedTimeframe === 'lastTwoWeeks'
          ? 'twoWeeklyData'
          : selectedTimeframe === 'lastFourWeeks'
            ? 'fourWeeklyData'
            : 'weeklyData',
      );
    }
  }, [selectedTimeframe]);

  if (snapshots === null || snapshots === undefined) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        {/* <h2 className="text-3xl font-bold">No Snapshots Found</h2> */}
      </div>
    );
  }

  const slicedData = snapshots.slice(0, 6);

  const visibilityMetricList = slicedData?.map((item) => ({
    name: item.node.title,
    value: Number(
      (item.node[timeframeData].at(-1).visibility * 100).toFixed(1),
    ),
  }));

  const engagementMetricList = slicedData?.map((item) => ({
    name: item.node.title,
    value: Number(item.node[timeframeData].at(-1).qualityEngagement.toFixed(0)),
  }));

  const sortedVisibility = visibilityMetricList.sort(
    (a, b) => b.value - a.value,
  );

  const sortedEngagement = engagementMetricList.sort(
    (a, b) => b.value - a.value,
  );

  if (variant !== 'main') {
    return (
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold">{title}</h2>
        <div className="my-4 grid grid-cols-3 gap-8">
          {loading && network !== NetworkStatus.fetchMore ? (
            [...Array(6)].map((_, index) => <GhostItem key={index} />)
          ) : (
            <>
              {snapshots?.map((item, index) => (
                <SnapsCard key={index} variant="md" snapshotItem={item.node} />
              ))}
              {network === NetworkStatus.fetchMore &&
                [...Array(6)].map((_, index) => <GhostItem key={index} />)}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex w-full flex-col gap-y-8 gap-x-40 md:flex-row">
        <TrendsMetricsChart
          metric={'Visibility'}
          metricList={sortedVisibility}
        />
        <TrendsMetricsChart
          metric={'Quality Engagement'}
          metricList={sortedEngagement}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="mt-6 text-3xl font-bold">{title}</h2>
        <div className="my-4 grid grid-cols-3 gap-8">
          {snapshots?.map((item, index) => (
            <SnapsCard key={index} variant="md" snapshotItem={item.node} />
          ))}
          {network === NetworkStatus.fetchMore &&
            [...Array(6)].map((_, index) => <GhostItem key={index} />)}
        </div>
      </div>
    </div>
  );
}

function TrendsFashionWeeks({ items }) {
  return <TrendsWorkInProgressCard />;
}

function TrendsWorkInProgressCard() {
  return (
    <div
      className="mx-auto my-10 max-w-md rounded-lg
border border-gray-200 bg-white p-6 text-center text-lg text-gray-600 shadow-md"
    >
      This feature is currently under development. Please check back later for
      updates.
    </div>
  );
}

function TrendsBrands() {
  return <TrendsWorkInProgressCard />;
}

const GhostItem = () => {
  return (
    <div className="flex flex-col">
      <div className={'ghost aspect-[54/25] rounded-xl'}></div>
      <h3 className="ghost mt-4 w-max text-2xl text-ghost">Black Shoes</h3>
      <p className="text-md ghost mt-1 h-6 w-max text-ghost">
        Year over Year Growth %40
      </p>
    </div>
  );
};

const TrendsCardGhost = ({ count, variant }) => {
  const variantMap = {
    sm: 'w-64',
    md: 'w-full',
  };

  return (
    <>
      <h2 className="mt-6 text-3xl font-bold">Top Trends</h2>
      <div
        className={`grid animate-pulse grid-cols-3 gap-4 rounded-xl pt-8 ${variantMap[variant]}`}
      >
        {[...Array(6)].map((_, index) => (
          <GhostItem key={index} />
        ))}
      </div>
      <div className="mt-4 flex items-center justify-center">
        <LazyLoadButton fetchMore={() => {}} cursorNext={null} disabled />
      </div>
      <h2 className="mt-6 text-3xl font-bold">Top Tones</h2>
      <div
        className={`grid animate-pulse grid-cols-3 gap-4 rounded-xl pt-8 ${variantMap[variant]}`}
      >
        {[...Array(6)].map((_, index) => (
          <GhostItem key={index} />
        ))}
      </div>
      <div className="mt-4 flex items-center justify-center">
        <LazyLoadButton fetchMore={() => {}} cursorNext={null} disabled />
      </div>
    </>
  );
};

const TrendSection = ({ title, section, variant }) => {
  const navigationType = useNavigationType();

  const { selectedTrendsCategory, selectedTimeframe, selectedSnapshotSort } =
    useAppFilters();

  const { selectedSocialWatch, audienceFilter } = useTrendsContext();

  const snapshotsQueryVariables = useMemo(
    () => ({
      cursor: null,
      filters: {
        apparel: selectedTrendsCategory?.length
          ? selectedTrendsCategory.map((a) => a.value.toString())
          : null,
        timeframe: selectedTimeframe || null,
        section,
      },
      audience: audienceFilter,
      sortField: selectedSnapshotSort || null,
    }),
    [
      selectedTrendsCategory,
      selectedTimeframe,
      audienceFilter,
      selectedSnapshotSort,
      section,
    ],
  );

  const {
    data,
    error,
    loading,
    fetchMore,
    networkStatus: network,
    refetch,
  } = useTrendsQuery('snapshotItems', {
    variables: snapshotsQueryVariables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (navigationType !== 'POP') {
      refetch();
    }
  }, [
    refetch,
    navigationType,
    audienceFilter,
    selectedTimeframe,
    selectedSnapshotSort,
    selectedTrendsCategory,
  ]);

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  if (loading && network !== NetworkStatus.fetchMore && variant !== 'main') {
    return (
      <Tabs.Content value="snapshots" className={'mt-6 h-full w-full pb-16'}>
        <div className="flex flex-col">
          <h2 className="text-3xl font-bold">{title}</h2>
          <div className="my-4 grid grid-cols-3 gap-8">
            {[...Array(6)].map((_, index) => (
              <GhostItem key={index} />
            ))}
          </div>
          <div className="flex items-center justify-center">
            <LazyLoadButton
              disabled={true}
              fetchMore={() => {}}
              cursorNext={null}
            />
          </div>
        </div>
      </Tabs.Content>
    );
  }

  if (loading && network !== NetworkStatus.fetchMore && variant === 'main') {
    return (
      <Tabs.Content value="snapshots" className={'mt-6 h-full w-full pb-16'}>
        <div className="mb-6 flex flex-col">
          <div className="flex w-full flex-col gap-y-8 gap-x-40 md:flex-row">
            <div className="ghost h-52 w-full rounded md:w-1/2"></div>
            <div className="ghost h-52 w-full rounded md:w-1/2"></div>
          </div>
        </div>
        <div className="flex flex-col">
          <h2 className="text-3xl font-bold">{title}</h2>
          <div className="my-4 grid grid-cols-3 gap-8">
            {[...Array(6)].map((_, index) => (
              <GhostItem key={index} />
            ))}
          </div>
          <div className="flex items-center justify-center">
            <LazyLoadButton
              disabled={true}
              fetchMore={() => {}}
              cursorNext={null}
            />
          </div>
        </div>
      </Tabs.Content>
    );
  }

  if (
    variant === 'main' &&
    selectedSocialWatch === true &&
    data?.snapshotItems?.edges?.length === 0
  ) {
    return (
      <div className="flex h-full items-center justify-center pt-10">
        <span className="text-3xl font-bold">No Snapshots Found</span>
      </div>
    );
  }

  if (
    variant === 'main' &&
    selectedSocialWatch === false &&
    data?.snapshotItems?.edges?.length === 0
  ) {
    return (
      <div className="flex h-full w-full items-start justify-center pt-40">
        <div className="h-max w-2/5 rounded-lg border border-secondary/30 bg-secondary/5 p-2 px-6 text-center shadow-sm">
          <h1 className="text-xl font-bold leading-8 text-black">
            Snapshot feature is not active on this market.
          </h1>
          <p className="text-lg">
            If you believe this is an error, please contact us at{' '}
            <a
              href="mailto:hello@tfashion.ai"
              className="font-semibold text-primary hover:underline hover:underline-offset-4"
            >
              hello@tfashion.ai
            </a>
          </p>
        </div>
      </div>
    );
  }

  if (data?.snapshotItems?.edges?.length === 0) {
    return <></>;
  }

  return (
    <div className="mt-6">
      <TrendsSnapshots
        title={title}
        snapshots={data?.snapshotItems?.edges}
        loading={loading}
        network={network}
        variant={section === 'TOP_TRENDS' ? 'main' : null}
      />
      <div className="flex items-center justify-center">
        <LazyLoadButton
          fetchMore={fetchMore}
          cursorNext={data?.snapshotItems?.pageInfo?.endCursor}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export function TrendsTabs() {
  const { activeTab, setActiveTab } = useTrendsContext();

  const navigate = useNavigate();
  const params = useParams();
  const [demoPopupState, setDemoPopupState] = useState(false);

  const {
    trendItemsData,
    trendItemsLoading,
    trendItemsError,
    fetchMoreTrendItems,
    trendItemsNetworkStatus,
    trendTonesData,
    trendTonesLoading,
    trendTonesError,
    fetchMoreTrendTones,
    trendTonesNetworkStatus,
    cursorNext,
    tonesCursorNext,
  } = useTrendsContext();

  if (trendItemsError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {trendItemsError.message}</span>
      </div>
    );
  }

  if (trendTonesError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {trendTonesError.message}</span>
      </div>
    );
  }

  const renderData = trendItemsData?.trendItems?.data || [];
  const tonesData = trendTonesData?.tones?.data || [];

  const sections = [
    { title: 'Top Trends', cursor: 'TOP_TRENDS' },
    { title: 'Top Designs', cursor: 'TOP_DESIGNS' },
    { title: 'Top Tones', cursor: 'TOP_TONES' },
    { title: 'Top Fabrics', cursor: 'TOP_FABRICS' },
    { title: 'Top Patterns', cursor: 'TOP_PATTERNS' },
  ];

  return (
    <>
      <DemoAccountModal
        isOpen={demoPopupState}
        onClose={() => setDemoPopupState(false)}
      />
      <div className="relative w-full overflow-y-auto py-[2.25rem]">
        <Tabs.Root
          value={activeTab}
          onValueChange={(tab) => {
            setActiveTab(tab);
            navigate(`/trends/${tab}/${params.marketId}/${params.audienceId}`);
          }}
          className={'relative'}
        >
          <Tabs.List
            aria-label="tabs"
            className={
              'flex items-center justify-between px-[3.75rem] pt-[1.125rem] pb-[1.5rem]'
            }
          >
            <div className={'flex gap-16 '}>
              {tabs.map((tab, i) => (
                <TabHeading label={tab.label} value={tab.value} key={i} />
              ))}
            </div>
          </Tabs.List>
          {activeTab === 'snapshots' && (
            <Tabs.Content
              value="snapshots"
              className={'h-full w-full px-[3.75rem]'}
            >
              {sections.map((section, i) => (
                <TrendSection
                  key={i}
                  title={section.title}
                  section={section.cursor}
                  variant={section.cursor === 'TOP_TRENDS' ? 'main' : null}
                />
              ))}
            </Tabs.Content>
          )}
          {renderData.length > 0 &&
            activeTab !== 'snapshots' &&
            tabs.map((tab, i) => (
              <Tabs.Content
                key={i}
                value={tab.value}
                className={'h-full w-full px-[3.75rem]'}
              >
                <tab.Component
                  items={renderData}
                  tones={tonesData}
                  network={trendItemsNetworkStatus}
                  networkStatus={trendTonesNetworkStatus}
                  loading={trendItemsLoading}
                  loadingTones={trendTonesLoading}
                  fetchMore={fetchMoreTrendItems}
                  fetchTones={fetchMoreTrendTones}
                  cursorNext={cursorNext}
                  tonesCursor={tonesCursorNext}
                />
              </Tabs.Content>
            ))}
        </Tabs.Root>
      </div>
    </>
  );
}
