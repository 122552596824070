import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { debounce, upperFirst } from 'lodash';
import {
  ChevronLeft,
  ChevronRight,
  Copy,
  DiamondPlus,
  Earth,
  Eraser,
  Folders,
  Fullscreen,
  HelpCircle,
  History,
  Home,
  ImagePlus,
  Lock,
  NotebookPen,
  Palette,
  Pen,
  Plus,
  Replace,
  ScanSearch,
  Search,
  Sparkles,
  SquareAsterisk,
  SwatchBook,
  Trash,
  Wand,
  X,
} from 'lucide-react';

import { NEW_PROMPT_SUGGESTION } from '@/api/studio/mutations';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Textarea } from '@/components/ui/textarea';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { AsideBarButton } from './components/AsidebarMenu';
import {
  CategoryButton,
  SidebarButton,
  SidebarCard,
  SidebarHeader,
  SidebarTitle,
} from './components/SidebarMenu';
import {
  // ImageCanvas,
  ImagePreview,
  StudioHome,
  TileImage,
} from './components/StudioContents';
import { StudioHistorySidebar } from './components/StudioHistorySidebar';
import { GenerationElapsed, UploadBox } from './components/StudioUtils';

export const NewStudio = () => {
  const [addNegativeKeywords, setAddNegativeKeywords] = useState(false);
  const [describing, setDescribing] = useState(false);
  const [createItem, setCreateItem] = useState({ icon: null, text: null });
  const [generateType, setGenerateType] = useState('ai-gen');
  const [selectedAdviceId, setSelectedAdviceId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState({
    selectedTemplateId: null,
    selectedTemplateIndex: null,
  });
  const [descHistory, setDescHistory] = useState([]);
  const [openCollections, setOpenCollections] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [isTile, setIsTile] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  // const [isResult, setIsResult] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generateSize, setGenerateSize] = useState('Portrait');
  const [positivePrompt, setPositivePrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [generateVisibility, setGenerateVisibility] = useState('PUBLIC');
  const [images, setImages] = useState([]);
  const [orientation, setOrientation] = useState('square');
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);

  const [promptSuggestions, { loading: promptLoading }] = useMutation(
    NEW_PROMPT_SUGGESTION,
  );

  const debouncedSuggestions = useCallback(
    debounce((value) => {
      if (value.trim()) {
        promptSuggestions({
          variables: {
            prompt: value,
          },
          onCompleted: (data) => {
            setSuggestedPrompts(data.studioSuggestNewPrompts.suggestions);
            setGenerateType('ai-gen');
          },
        });
      }
    }, 1000), // 1000ms debounce time
    [promptSuggestions],
  );

  const handlePromptChange = (e) => {
    const value = e.target.value;
    setPositivePrompt(value);
    debouncedSuggestions(value);
    setDescribing(true);
  };

  const {
    referenceError,
    handleNewGenerate,
    designTask,
    openTools,
    setOpenTools,
    setNewPayload,
  } = useStudioContext();

  useEffect(() => {
    setNewPayload((prev) => ({
      ...prev,
      positivePrompt,
      negativePrompt,
      orientation: {
        label: generateSize,
        value: generateSize.toLowerCase(),
      },
      visibility: generateVisibility,
    }));
  }, [positivePrompt, negativePrompt, generateSize, generateVisibility]);

  useEffect(() => {
    if (designTask?.loading === true) {
      setIsHome(false);
      setIsGenerating(true);
    } else if (designTask?.loading === false) {
      setIsGenerating(false);
      setIsTile(true);
      setOrientation(designTask?.payload?.orientation?.value);
      setImages(designTask?.images.map((image) => ({ url: image.url })));
      setDescHistory([...descHistory, positivePrompt]);
    }
  }, [designTask]);

  const templates = [
    {
      image: 'https://via.placeholder.com/150',
      title: 'Metallic',
      description: 'Metallic design with a shiny finish',
      list: [
        'Metallic steampunk-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
        'Metallic steampunk-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
        'Metallic steampunk-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
      ],
    },
    {
      image: 'https://via.placeholder.com/150',
      title: 'White Noise',
      description: 'White noise design with a grainy texture',
      list: [
        'White noise-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
        'White noise-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
        'White noise-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
      ],
    },
    {
      image: 'https://via.placeholder.com/150',
      title: 'Denim',
      description: 'Denim design with a rough texture',
      list: [
        'Denim-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
        'Denim-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
        'Denim-inspired corset dress in an abandoned train yard, accessorized with happy flower future vibrant.',
      ],
    },
    {
      image: 'https://via.placeholder.com/150',
      title: 'Western Gothics',
      description: 'Western gothics design with a dark theme',
      list: [
        'Western gothics-inspired corset dress in an abandoned train yard future vibrant.',
        'Western gothics-inspired corset dress in an abandoned train yard, accessorized with happy flower.',
        'Western gothics-inspired corset dress accessorized with happy flower future vibrant.',
      ],
    },
  ];

  const collections = [
    {
      image: [
        { url: 'https://via.placeholder.com/150/00FF00' },
        { url: 'https://via.placeholder.com/150' },
        { url: 'https://via.placeholder.com/150/0000FF' },
        { url: 'https://via.placeholder.com/150/FF0000' },
      ],
      title: 'Metallic',
      description: '300 shots',
    },
    {
      image: [
        { url: 'https://via.placeholder.com/150/a1a1a1' },
        { url: 'https://via.placeholder.com/150/eeeeee' },
        { url: 'https://via.placeholder.com/150/aa1212' },
        { url: 'https://via.placeholder.com/150/22ff22' },
      ],
      title: 'White Noise',
      description: '200 shots',
    },
  ];

  const handleTitleIconChanges = (icon, text) => {
    setCreateItem({ icon, text });
  };

  return (
    <div className="grid h-full grid-cols-[1fr,5fr] items-start justify-start">
      {/* Sidebar */}
      {createItem.text === null && (
        <div className="flex h-full flex-col gap-6 border-r border-secondary/50 py-6 px-2 lg:px-6">
          <div className="flex items-center gap-x-2 rounded bg-primary px-4 py-2 text-[1rem] font-bold leading-[1.75rem] text-white">
            <Home size={16} />
            <h2>Home</h2>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-bold text-primary">Create</h3>
            <div className="flex flex-col gap-0">
              <SidebarButton
                variant="nav"
                icon={Plus}
                text="Create an Apparel"
                onClick={() =>
                  handleTitleIconChanges(Plus, 'Create an Apparel')
                }
              />
              <SidebarButton
                variant="nav"
                icon={SquareAsterisk}
                text="Create a Pattern"
                onClick={() =>
                  handleTitleIconChanges(SquareAsterisk, 'Create a Pattern')
                }
              />
              <SidebarButton
                variant="nav"
                icon={NotebookPen}
                text="Create from Scratch"
                onClick={() =>
                  handleTitleIconChanges(NotebookPen, 'Create from Scratch')
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-bold text-primary">Edit Tools</h3>
            <div className="flex flex-col gap-0">
              <SidebarButton
                variant="nav"
                icon={SquareAsterisk}
                text="Apply a Pattern"
                onClick={() =>
                  handleTitleIconChanges(SquareAsterisk, 'Apply a Pattern')
                }
              />
              <SidebarButton
                variant="nav"
                icon={Eraser}
                text="Remove Background"
                onClick={() =>
                  handleTitleIconChanges(Eraser, 'Remove Background')
                }
              />
              <SidebarButton
                variant="nav"
                icon={Replace}
                text="Upscale Enhance"
                onClick={() =>
                  handleTitleIconChanges(Replace, 'Upscale Enhance')
                }
              />
              <SidebarButton
                variant="nav"
                icon={ImagePlus}
                text="Inpainting"
                onClick={() => handleTitleIconChanges(ImagePlus, 'Inpainting')}
              />
              <SidebarButton
                variant="nav"
                icon={Palette}
                text="Change Color"
                onClick={() => handleTitleIconChanges(Palette, 'Change Color')}
              />
            </div>
          </div>
        </div>
      )}
      {createItem.text !== null && (
        <div className="flex h-full flex-col justify-between border-r border-secondary/50 p-6">
          <div id="top" className="flex h-full flex-col gap-6">
            <SidebarHeader
              variant="back"
              text="Home"
              onClick={() => setCreateItem({ icon: null, text: null })}
            />
            <SidebarHeader icon={createItem.icon} text={createItem.text} />
            <div className="flex flex-col gap-2">
              <SidebarTitle text="Describe the Design" />
              <Textarea
                variant="studio"
                icon={Sparkles}
                className="focus-visible:none h-[6.75rem] resize-none rounded border border-night/5 bg-secondary/5 py-2 pr-6 text-night transition duration-300 ease-in-out placeholder:text-night/70 hover:border-primary hover:bg-[#FFFDFD] focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none"
                placeholder="Enter Description"
                value={positivePrompt}
                onChange={handlePromptChange}
              />
            </div>
            {describing && (
              <div
                className={cn(
                  'flex flex-col gap-y-6 transition-all duration-1000 ease-in-out transform opacity-0',
                  describing && 'opacity-100 translate-y-0',
                )}
                style={{ transitionProperty: 'opacity, transform' }}
              >
                <div id="toggle group">
                  <ToggleGroup type="single" className="gap-x-0">
                    <ToggleGroupItem
                      variant="nav"
                      value="ai-gen"
                      active={generateType === 'ai-gen'}
                      onClick={() => setGenerateType('ai-gen')}
                    >
                      <Sparkles size={16} className="pr-0.5" />
                      AI
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      variant="nav"
                      value="templates"
                      active={generateType === 'templates'}
                      onClick={() => setGenerateType('templates')}
                    >
                      <Copy size={16} className="pr-0.5" />
                      Templates
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      variant="nav"
                      value="history"
                      active={generateType === 'history'}
                      onClick={() => setGenerateType('history')}
                    >
                      <History size={16} className="pr-0.5" />
                      History
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
                {generateType === 'ai-gen' && promptLoading && (
                  <div className="flex flex-col gap-y-2">
                    {Array.from({ length: 5 }).map((_, i) => (
                      <SidebarCard
                        key={i}
                        index={i}
                        variant="ghost"
                        value="Your prompt is being generated.."
                      />
                    ))}
                  </div>
                )}
                {generateType === 'ai-gen' && (
                  <div className="flex flex-col gap-y-2">
                    {suggestedPrompts.map((advice, i) => (
                      <SidebarCard
                        key={i}
                        index={i}
                        value={upperFirst(advice.value)}
                        selectedId={selectedAdviceId}
                        onClick={() => {
                          setSelectedAdviceId(i);
                          setPositivePrompt(upperFirst(advice.value));
                        }}
                      />
                    ))}
                  </div>
                )}
                {generateType === 'templates' && (
                  <div className="flex flex-col gap-y-2">
                    {selectedTemplateId === null ? (
                      templates.map((template, i) => (
                        <CategoryButton
                          key={i}
                          category={template}
                          index={i}
                          setId={setSelectedTemplateId}
                        />
                      ))
                    ) : (
                      <div className="flex flex-col gap-y-2">
                        <SidebarHeader
                          icon={ChevronLeft}
                          text={templates[selectedTemplateId].name}
                          onClick={() => {
                            setSelectedTemplateId(null);
                            setSelectedTemplate({
                              selectedTemplateId: null,
                              selectedTemplateIndex: null,
                            });
                          }}
                          variant="back"
                        />
                        {templates[selectedTemplateId].list.map((item, i) => (
                          <SidebarCard
                            key={i}
                            index={i}
                            value={item}
                            selectedId={selectedTemplate.selectedTemplateIndex}
                            onClick={() => {
                              setSelectedTemplate({
                                selectedTemplateId,
                                selectedTemplateIndex: i,
                              });
                              setPositivePrompt(item);
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {generateType === 'history' && (
                  <div className="flex flex-col gap-y-2">
                    {descHistory.map((desc, i) => (
                      <SidebarCard
                        key={i}
                        index={i}
                        value={desc}
                        selectedId={selectedAdviceId}
                        onClick={() => {
                          setSelectedAdviceId(i);
                          setPositivePrompt(desc);
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {!addNegativeKeywords ? (
              <SidebarButton
                icon={Plus}
                text="Add Negative Keywords"
                className="gap-2 px-4 py-2"
                onClick={() => setAddNegativeKeywords(!addNegativeKeywords)}
              />
            ) : (
              <div className="flex flex-col gap-y-2">
                <SidebarTitle text="Negative Keywords" />
                <Textarea
                  variant="studio"
                  icon={Trash}
                  onIconClick={() =>
                    setAddNegativeKeywords(!addNegativeKeywords)
                  }
                  value={negativePrompt}
                  onChange={(e) => {
                    setNegativePrompt(e.target.value);
                  }}
                  className="focus-visible:none h-[6.75rem] resize-none rounded border border-night/5 bg-secondary/5 py-2 pr-6 text-night transition duration-300 ease-in-out placeholder:text-night/70 hover:border-primary hover:bg-[#FFFDFD] focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none"
                  placeholder="Describe details you don't want in your design"
                />
              </div>
            )}
            {/* Upload Images */}
            <div className="flex flex-row gap-x-4">
              <UploadBox
                title="Reference Image"
                icon={ImagePlus}
                text="Drop an image or click to upload"
                type="image"
                onClick={() => console.log('Upload Image')}
              />
              <UploadBox
                title="Reference Pattern"
                icon={SquareAsterisk}
                text="Drop a pattern or click to upload"
                type="pattern"
                onClick={() => console.log('Upload Pattern')}
              />
            </div>
            {referenceError && (
              <div className="text-sm font-normal text-red-600">
                {referenceError}
              </div>
            )}
            <div className="flex flex-row">
              <Accordion className="w-full" type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger>
                    <SidebarTitle text="Additional Settings" />
                  </AccordionTrigger>
                  <AccordionContent>
                    <SidebarTitle text="Size" />
                    <ToggleGroup type="single" className="gap-x-2 pt-4">
                      <ToggleGroupItem
                        variant="studio"
                        value="Square"
                        active={generateSize === 'Square'}
                        onClick={() => setGenerateSize('Square')}
                      >
                        Square
                      </ToggleGroupItem>
                      <ToggleGroupItem
                        variant="studio"
                        value="Portrait"
                        active={generateSize === 'Portrait'}
                        onClick={() => setGenerateSize('Portrait')}
                      >
                        Portrait
                      </ToggleGroupItem>
                      <ToggleGroupItem
                        variant="studio"
                        value="Landscape"
                        active={generateSize === 'Landscape'}
                        onClick={() => setGenerateSize('Landscape')}
                      >
                        Landscape
                      </ToggleGroupItem>
                    </ToggleGroup>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div id="bottom" className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-4">
              <SidebarTitle text="Visibility" />
              <div className="flex flex-row items-center justify-center gap-x-4 [&>*]:w-full">
                <ToggleGroup type="single" className="gap-x-2 pt-4">
                  <ToggleGroupItem
                    variant="studio"
                    value="PUBLIC"
                    active={generateVisibility === 'PUBLIC'}
                    onClick={() => setGenerateVisibility('PUBLIC')}
                  >
                    <Earth size={16} className="mr-1" />
                    Public
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    variant="studio"
                    value="CONFIDENTIAL"
                    active={generateVisibility === 'CONFIDENTIAL'}
                    onClick={() => setGenerateVisibility('CONFIDENTIAL')}
                  >
                    <Lock size={16} className="mr-1" />
                    Private
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            </div>
            <SidebarButton
              variant="generate"
              text="Generate"
              icon={Wand}
              onClick={() => {
                handleNewGenerate();
                setIsHome(false);
                setIsGenerating(true);
                setIsPreview(false);
                setIsTile(false);
              }}
            />
          </div>
        </div>
      )}
      {/* Complement of Sidebar */}
      <div className="relative grid h-full grid-cols-[1fr,9%] overflow-hidden">
        {/* Main Section */}
        <div className="flex flex-col overflow-auto border-r border-secondary/50 bg-[#FAF8F8] p-6">
          {/* Create an Apparel Page */}
          {!isHome && (
            <div
              className={`relative ${openTools ? 'flex flex-col' : 'flex h-full flex-col md:grid md:grid-cols-[1fr,0.8fr]'} items-start justify-start`}
            >
              <div
                className="flex flex-col gap-y-6 rounded-lg border border-secondary/50 bg-primaryLight p-6"
                id="result"
              >
                <div className="flex items-center justify-between">
                  {isTile && (
                    <h2 className="text-2xl font-bold leading-6 text-primary">
                      Result
                    </h2>
                  )}
                  {isGenerating && (
                    <h2 className="text-2xl font-bold leading-6 text-primary">
                      Generate <GenerationElapsed />
                    </h2>
                  )}
                  {isPreview && (
                    <div className="flex items-center gap-x-4">
                      <SidebarButton
                        text="Save"
                        className="border bg-primary py-2 px-4 font-bold text-white after:border-primary"
                        onClick={() => console.log('Saved')}
                      />
                      <SidebarButton
                        text="Download"
                        className="py-2 px-4 font-bold"
                        onClick={() => console.log('Downloaded')}
                      />
                      <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
                        <X
                          size={36}
                          onClick={() => setIsPreview(false)}
                          strokeWidth={1.5}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {isTile && (
                  <div className="grid grid-cols-2 grid-rows-2 gap-6">
                    {images.map((image, index) => (
                      <TileImage
                        key={index}
                        imageUrl={image.url}
                        aspect={
                          orientation === 'square'
                            ? '[1/1]'
                            : orientation === 'portrait'
                              ? '[3/4]'
                              : '[4/3]'
                        }
                        alt={image.studioItemId}
                      />
                    ))}
                  </div>
                )}
                {isPreview && (
                  <ImagePreview
                    images={[
                      {
                        url: 'https://via.placeholder.com/150',
                        alt: 'Image 1',
                      },
                      {
                        url: 'https://via.placeholder.com/250',
                        alt: 'Image 2',
                      },
                      {
                        url: 'https://via.placeholder.com/350',
                        alt: 'Image 3',
                      },
                      {
                        url: 'https://via.placeholder.com/450',
                        alt: 'Image 4',
                      },
                    ]}
                  />
                )}
                {/* {isResult && (
                  <div className="flex flex-col gap-y-6">
                    <ImageCanvas
                      imageUrl="https://via.placeholder.com/450"
                      alt="Design Without Limits"
                    />
                  </div>
                )} */}
                {isGenerating && (
                  <div className="flex flex-col gap-y-6">
                    <div className="ghost flex aspect-square items-center justify-center"></div>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-y-6 p-6" id="more-ideas">
                <div>
                  <h2 className="text-2xl font-bold leading-6 text-primary">
                    More ideas to explore
                  </h2>
                </div>
                <div
                  className={`grid ${openTools ? 'grid-cols-4' : 'grid-cols-3'} gap-6`}
                >
                  {Array.from({ length: 12 }).map((_, index) => (
                    <div
                      key={index}
                      className="ghost aspect-[3/4] rounded"
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {openTools && (
            <div
              style={{
                boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
                transition: 'right 2s ease',
              }}
              className="absolute top-0 right-[7.5%] flex h-full w-[30rem] items-start justify-start border-l border-secondary/50"
            >
              <div className="flex h-full w-full flex-col items-start gap-6 bg-white p-6">
                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <div className="flex gap-4">
                    <SidebarButton
                      text="Save"
                      className="border bg-primary py-2 px-4 font-bold text-white after:border-primary"
                      onClick={() => console.log('Saved')}
                    />
                    <SidebarButton
                      text="Download"
                      className="py-2 px-4 font-bold"
                      onClick={() => console.log('Downloaded')}
                    />
                  </div>
                  <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
                    <X
                      size={36}
                      onClick={() => setOpenTools(false)}
                      strokeWidth={1.5}
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col gap-4 pt-2">
                  <h2 className="text-2xl font-bold leading-6 text-night">
                    Design Tools
                  </h2>
                </div>
                <div className="grid grid-cols-3 gap-x-4 gap-y-6">
                  <SidebarButton
                    text="Variants"
                    icon={DiamondPlus}
                    variant={'tool'}
                    onClick={() => console.log('Variants')}
                  />
                  <SidebarButton
                    text="Upscale"
                    icon={Replace}
                    variant={'tool'}
                    onClick={() => console.log('Upscale')}
                  />
                  <SidebarButton
                    text="RemoveBg"
                    icon={Eraser}
                    variant={'tool'}
                    onClick={() => console.log('Remove Bg')}
                  />
                  <SidebarButton
                    text="Pattern"
                    icon={SquareAsterisk}
                    variant={'tool'}
                    onClick={() => console.log('Pattern')}
                  />
                  <SidebarButton
                    text="Zoom in"
                    icon={Fullscreen}
                    variant={'tool'}
                    onClick={() => console.log('Zoom in')}
                  />
                  <SidebarButton
                    text="Zoom out"
                    icon={ScanSearch}
                    variant={'tool'}
                    onClick={() => console.log('Zoom out')}
                  />
                  <SidebarButton
                    text="Color Tone"
                    icon={Palette}
                    variant={'tool'}
                    onClick={() => console.log('Color Tone')}
                  />
                  <SidebarButton
                    text="Inpainting"
                    icon={ImagePlus}
                    variant={'tool'}
                    onClick={() => console.log('Inpainting')}
                  />
                  <SidebarButton
                    text="Img Manip."
                    icon={ImagePlus}
                    variant={'tool'}
                    onClick={() => console.log('Image Manipulation')}
                  />
                  <SidebarButton
                    text="Bg Manip."
                    icon={Pen}
                    variant={'tool'}
                    onClick={() => console.log('Bg Manipulation')}
                  />
                </div>
                <div className="flex w-full flex-col gap-2">
                  <h3 className="text-lg font-bold leading-8 text-night">
                    Prompt
                  </h3>
                  <p className="text-sm text-night">
                    modern graphics with water texture calming colors nature
                    happy flower future vibrant monochromatic blue.
                  </p>
                  <p className="text-xs font-bold text-secondary">12.07.2024</p>
                </div>
                <div className="flex w-full flex-col gap-2">
                  <h3 className="text-lg font-bold leading-8 text-night">
                    Size
                  </h3>
                  <p className="text-sm font-bold text-secondary">Square</p>
                </div>
              </div>
            </div>
          )}
          {/* Home Page */}
          {isHome && <StudioHome />}
        </div>
        {/* Aside bar */}
        <div className="fixed top-[5rem] right-0 flex h-[calc(100vh-10%)] w-[7.5%] flex-col items-center justify-between gap-4 overflow-auto bg-white py-6 px-1 lg:px-4">
          <div className="flex w-full flex-col gap-4">
            <AsideBarButton
              icon={Folders}
              text="Collections"
              active={openCollections}
              onClick={() => {
                setOpenCollections(!openCollections);
                setOpenHistory(false);
              }}
            />
            <AsideBarButton icon={SwatchBook} text="Designs" />
            <AsideBarButton icon={SquareAsterisk} text="Patterns" />
            <AsideBarButton icon={NotebookPen} text="Sketches" />
            <AsideBarButton
              icon={History}
              text="History"
              active={openHistory}
              onClick={() => {
                setOpenHistory(!openHistory);
                setOpenCollections(false);
              }}
            />
          </div>
          <div className="flex w-full flex-col gap-4">
            <AsideBarButton icon={HelpCircle} text="Help" />
          </div>
        </div>
        {/* Collection Modal */}
        {openCollections && (
          <div
            style={{
              boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
              transition: 'right 2s ease',
            }}
            className="absolute top-0 right-[9%] flex h-full w-80 items-start justify-start border-x border-secondary/50"
          >
            <div className="flex h-full w-full flex-col items-start justify-between bg-white py-6 px-2 lg:px-6">
              <div className="flex w-full flex-col gap-4">
                <SidebarHeader
                  text="Collections"
                  variant="back"
                  icon={ChevronRight}
                  onClick={() => {
                    setOpenCollections(false);
                    setOpenHistory(false);
                  }}
                />
                {/* Search bar */}
                <div className="focus-visible:none flex w-full flex-row items-start justify-between border-b border-night py-1 text-night focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none">
                  <input
                    type="text"
                    placeholder="Search in All..."
                    className="w-full bg-transparent text-sm focus:outline-none"
                  />
                  <Search size={18} />
                </div>
                <div className="flex flex-col gap-y-4">
                  {collections.map((collection, i) => (
                    <CategoryButton
                      key={i}
                      category={collection}
                      index={i}
                      setId={setSelectedTemplateId}
                      variant="collection"
                    />
                  ))}
                </div>
              </div>
              <div className="flex w-full flex-row items-center justify-center gap-x-4">
                <SidebarButton
                  className="flex w-full items-center justify-center rounded py-4 font-bold"
                  text="Create New Collection"
                />
              </div>
            </div>
          </div>
        )}
        {openHistory && (
          <StudioHistorySidebar
            setOpenHistory={setOpenHistory}
            setOpenCollections={setOpenCollections}
          />
        )}
      </div>
    </div>
  );
};
