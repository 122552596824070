import { clsx } from 'clsx';
import { capitalize } from 'lodash';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function getInitials(name) {
  return name
    .split(' ')
    .map((part) => part.charAt(0))
    .join('')
    .toUpperCase();
}

export function deepEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function formatLabel(string) {
  return capitalize(string.replaceAll('-', ' '));
}

export const stringFormatter = (str) =>
  str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const downloadFile = (url, filename) => {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error('Resource URL not provided! You need to provide one'));
    }

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (filename && filename.length) a.download = filename;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);

        resolve();
      })
      .catch((error) => reject(error));
  });
};
