import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { GENERATE_STUDIO_ITEM, STUDIO_TASK } from '@/api/studio/mutations';
import { deepEqual } from '@/helpers/utils';
import {
  conceptOptions,
  orientationOptions,
} from '@/pages/general/studio/components/options';

export const StudioContext = createContext();

const defaultConcept = conceptOptions[0];
const defaultOrientation = orientationOptions[0];

export function StudioProvider({ children }) {
  const [referenceImage, setReferenceImage] = useState(null);
  const [referencePattern, setReferencePattern] = useState(null);
  const [referenceError, setReferenceError] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const [generateSize, setGenerateSize] = useState('Square');
  const [positivePrompt, setPositivePrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [generateVisibility, setGenerateVisibility] = useState('PUBLIC');

  const [payload, setPayload] = useState({
    item: null,
    design: null,
    concept: defaultConcept,
    orientation: defaultOrientation,
    mode: 'GUIDED',
  });

  const [newPayload, setNewPayload] = useState({
    mode: 'EXPERT',
    positivePrompt,
    negativePrompt,
    visibility: generateVisibility,
    referenceImages: referenceImage
      ? [{ entityType: 'FILE_UPLOAD', entityId: referenceImage.id }]
      : [],
    referencePatterns: referencePattern
      ? [{ entityType: 'FILE_UPLOAD', entityId: referencePattern.id }]
      : [],
    orientation: {
      label: generateSize,
      value: generateSize.toLowerCase(),
    },
  });

  const [designTask, setDesignTask] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'get-inspired',
  });

  const [imagePopup, setImagePopup] = useState({
    isOpen: false,
    designTask: null,
  });

  const activeTab = searchParams.get('tab') || 'get-inspired';
  const setActiveTab = (tab) => {
    setSearchParams({ tab });
  };

  const [
    generateStudioItem,
    { data: generateStudioItemData, error: generateStudioItemError },
  ] = useMutation(GENERATE_STUDIO_ITEM);

  const [checkStudioTask] = useMutation(STUDIO_TASK);

  const updateDesignTask = (newFields) => {
    setDesignTask((prevDesignTask) => ({
      ...prevDesignTask,
      ...newFields,
    }));
  };

  const checkJobStatus = async (jobId) => {
    const { data } = await checkStudioTask({
      variables: {
        id: jobId,
      },
    });

    if (data?.studioTask?.status === 'COMPLETED') {
      updateDesignTask({
        loading: false,
        result: data.studioTask,
        ...data.studioTask,
      });
      return true;
    }

    return false;
  };

  useEffect(() => {
    let interval;

    if (generateStudioItemData) {
      const jobId = generateStudioItemData.generateStudioItem.id;

      const newDesignTask = {
        payload,
        id: jobId,
      };

      // Only update designTask if there is a meaningful change
      if (!deepEqual(newDesignTask, designTask)) {
        updateDesignTask(newDesignTask);
      }

      interval = setInterval(async () => {
        const isCompleted = await checkJobStatus(jobId);
        if (isCompleted) {
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [generateStudioItemData]);

  useEffect(() => {
    if (generateStudioItemError) {
      updateDesignTask({
        loading: false,
        error: generateStudioItemError,
      });
    }
  }, [generateStudioItemError]);

  const handleGenerate = () => {
    if (!payload.item && payload?.mode === 'GUIDED') {
      return;
    }

    if (!payload?.positivePrompt && payload?.mode === 'EXPERT') {
      return;
    }

    setDesignTask({
      payload,
      loading: true,
      startedAt: new Date(),
    });

    setActiveTab('design-task');

    const backendPayload = JSON.parse(JSON.stringify(payload));
    if (backendPayload?.color) {
      delete backendPayload.color.hex;
    }

    generateStudioItem({
      variables: {
        payload: backendPayload,
      },
    });
  };

  const handleNewGenerate = () => {
    setDesignTask({
      payload: newPayload,
      loading: true,
      startedAt: new Date(),
    });

    generateStudioItem({
      variables: {
        payload: newPayload,
      },
    });
  };

  return (
    <StudioContext.Provider
      value={{
        payload,
        setPayload,
        designTask,
        setDesignTask,
        activeTab,
        setActiveTab,
        handleGenerate,
        imagePopup,
        setImagePopup,

        handleNewGenerate,
        setNewPayload,
        referenceImage,
        setReferenceImage,
        referencePattern,
        setReferencePattern,
        referenceError,
        setReferenceError,
        openTools,
        setOpenTools,
        generateSize,
        setGenerateSize,
        positivePrompt,
        setPositivePrompt,
        negativePrompt,
        setNegativePrompt,
        generateVisibility,
        setGenerateVisibility,
      }}
    >
      {children}
    </StudioContext.Provider>
  );
}

export const useStudioContext = () => useContext(StudioContext);
