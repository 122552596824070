import { useState } from 'react';

import { Circles } from './Circles';
import { ToneCards } from './ToneCards';

export function Tones() {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="relative h-8 w-full max-w-[5.5rem]"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Circles />
      <ToneCards visible={visible} />
    </div>
  );
}
