import * as Tabs from '@radix-ui/react-tabs';

import { TabHeading } from '@/components/TabHeading';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { SocialMediaImages } from '@/pages/general';
import { FashionWeekItems } from '@/pages/general/explore/FashionItems';

const tabs = [
  // {
  //   label: 'All',
  //   value: 'all',
  //   Component: SocialMediaImages
  // },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: FashionWeekItems,
  },
  {
    label: 'Social Media',
    value: 'social-media',
    Component: SocialMediaImages,
  },
  // {
  //   label: 'AI Designs',
  //   value: 'ai-designs',
  //   Component: SocialMediaImages
  // }
];

export function ExploreTabs({ className }) {
  const { activeTab, setActiveTab } = useExploreContext();

  return (
    <div
      className={cn(
        'h-full w-full overflow-hidden relative py-[2rem]',
        className,
      )}
    >
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative h-full'}
      >
        <Tabs.List
          aria-label="tabs"
          className={
            'flex h-20 items-center  justify-between px-[3.75rem] pb-[1.5rem] '
          }
        >
          <div className={'flex gap-16'}>
            {tabs.map((tab, i) => (
              <TabHeading label={tab.label} value={tab.value} key={i} />
            ))}
          </div>
        </Tabs.List>

        {tabs.map((tab, i) => (
          <Tabs.Content
            key={i}
            value={tab.value}
            className={'h-full w-full overflow-scroll px-[3.75rem] pb-[4rem]'}
          >
            <tab.Component />
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </div>
  );
}
