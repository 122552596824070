import { Navigate } from 'react-router';
import { useQuery } from '@apollo/client';

import { GET_STUDIO_ITEM } from '@/api/studio/queries';
import { AttributePill, TonePill } from '@/components/Pills';
import { useToast } from '@/hooks/useToast';

import { Image } from './components/Image';
import { AttributesSkeleton, ImageSkeleton } from './components/Skeletons';
import { AttributesWrapper, Wrapper } from './index';

export function StudioItemHeader({ header }) {
  return (
    <div className="flex flex-col gap-[0.675rem]">
      <h3 className="text-lg font-semibold capitalize leading-none text-primary">
        {header?.organization.name}
      </h3>
      <h1 className="text-[2.5rem] font-semibold capitalize leading-tight">
        {header?.createdBy.fullName}
      </h1>
    </div>
  );
}

function StudioItemGuided({ data, header, loading }) {
  return (
    <AttributesWrapper
      {...{
        item: data,
        loading,
        header,
      }}
    >
      <div className="flex flex-wrap items-center gap-4">
        <AttributePill label="visibility" value={data?.visibility} />
        {data?.payload?.gender && (
          <AttributePill label="gender" value={data?.payload?.gender?.value} />
        )}
        {data?.payload?.location && (
          <AttributePill label="location" value={data?.payload?.location} />
        )}
        <AttributePill
          label="concept"
          value={data?.payload?.concept?.value?.replaceAll('-', ' ')}
        />
        <AttributePill
          label="orientation"
          value={data?.payload?.orientation?.value}
        />
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold capitalize">
          {data?.payload?.item?.value}
        </h1>

        <div className="flex flex-wrap items-center gap-4">
          {data?.payload?.design?.map(
            (design) =>
              design && (
                <AttributePill
                  key={design?.value}
                  label={design?.attributeClass?.replaceAll('-', ' ')}
                  value={design?.value?.replaceAll('-', ' ')}
                />
              ),
          )}
          {data?.payload?.color?.slice(0, 3).map((color) => (
            <TonePill key={color.hex} {...color} />
          ))}
        </div>
      </div>
    </AttributesWrapper>
  );
}

function StudioItemExpert({ data, header, loading }) {
  return (
    <AttributesWrapper
      {...{
        item: data,
        header,
        loading,
      }}
    >
      <div className="flex flex-wrap items-center gap-4">
        <AttributePill label="visibility" value={data?.visibility} />
        <AttributePill
          label="concept"
          value={data?.payload?.concept?.value || 'DEFAULT'}
        />
        <AttributePill
          label="orientation"
          value={data?.payload?.orientation?.value || 'PORTRAIT'}
        />
      </div>

      <div className="mt-4 flex flex-col gap-4">
        <h1 className="text-lg font-bold leading-none">Prompt</h1>
        <p className="text-lg">{data?.payload?.positivePrompt}</p>
      </div>

      <div className="mt-4 flex flex-col gap-4">
        <h1 className="text-lg font-bold leading-none">Negative Prompt</h1>
        <p className="text-lg">{data?.payload?.negativePrompt}</p>
      </div>
    </AttributesWrapper>
  );
}

const generationModeToComponentMap = {
  GUIDED: StudioItemGuided,
  EXPERT: StudioItemExpert,
};

export function StudioItem({ id }) {
  const toast = useToast();

  const { data, loading } = useQuery(GET_STUDIO_ITEM, {
    variables: {
      id,
    },
    skip: !id,
  });

  if (loading) {
    return (
      <Wrapper>
        <ImageSkeleton />
        <AttributesSkeleton />
      </Wrapper>
    );
  }

  if (!loading && !data) {
    toast.createToast({
      message: 'Something went wrong',
      type: 'error',
      position: 'top-right',
    });

    return <Navigate to={'/studio'} replace={true} />;
  }

  const Attributes = generationModeToComponentMap[data?.studioItem.node.mode];

  return (
    <Wrapper>
      <Image
        {...{
          loading,
          image: data?.studioItem.node.image,
        }}
      />
      {Attributes && (
        <Attributes
          {...{
            data: data?.studioItem.node,
            header: {
              createdBy: data?.studioItem.node.createdBy,
              organization: data?.studioItem.node.organization,
            },
            loading,
          }}
        />
      )}
    </Wrapper>
  );
}
