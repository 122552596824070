import { createContext, useState } from 'react';

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [collectionsLayout, setCollectionsLayout] = useState('gallery');
  const [currentCollection, setCurrentCollection] = useState(null);

  function changeCollectionsLayout(layout) {
    if (!['gallery', 'two-col', 'three-col'].includes(layout)) {
      throw new Error('Invalid layout');
    }

    setCollectionsLayout(layout);
  }

  return (
    <AppContext.Provider
      value={{
        collectionsLayout,
        changeCollectionsLayout,
        currentCollection,
        setCurrentCollection,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
