/* eslint-disable prettier/prettier */

import { gql } from '@apollo/client';

export const GET_STUDIO_TASK_HISTORY = gql`
  query studioTaskHistory($cursor: String) {
    studioTaskHistory(cursor: $cursor) {
      edges {
        node {
          id
          seed
          images {
            url
            width
            height
            blurhash
            studioItemId
          }
          createdBy {
            id
            fullName
          }
          payload
          visibility
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_SIMILAR_IMAGES = gql`
  query studioSimilarImages($payload: GenerateImageInput!) {
    studioSimilarImages(payload: $payload) {
      node {
        id
        seed
        image {
          url
          width
          height
          blurhash
          studioItemId
        }
        createdBy {
          id
          fullName
        }
        payload
        createdAt
      }
    }
  }
`;

export const GET_INSPIRED_IMAGES = gql`
  query getInspiredImages($cursor: String) {
    getInspiredImages(cursor: $cursor) {
      edges {
        node {
          id
          seed
          image {
            url
            width
            height
            blurhash
            studioItemId
          }
          createdBy {
            id
            fullName
          }
          payload
          visibility
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_STUDIO_ITEM = gql`
  query studioItem($id: String) {
    studioItem(id: $id) {
      node {
        id
        image {
          url
          width
          height
          blurhash
        }
        createdBy {
          id
          fullName
        }
        organization {
          name
        }
        payload
        visibility
        mode
        status
        createdAt
      }
    }
  }
`;

export const GENERATE_FILE_UPLOAD_URL = gql`
  query generateFileUploadUrl(
    $fileVisibility: String!
    $fileName: String!
    $mimeType: String!
  ) {
    generateFileUploadUrl(
      fileVisibility: $fileVisibility
      fileName: $fileName
      mimeType: $mimeType
    ) {
      entityId
      signedUrl
    }
  }
`;
