import React from 'react';
import Highlighter from 'react-highlight-words';
import { debounce } from 'lodash';

import { Button } from '@/components/Button';
import { FormInput, FormLabel } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import { colorMap } from '@/constants';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn, stringFormatter } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';

function FiltersHeading() {
  const { clearAll } = useExploreContext();

  return (
    <div className="flex items-end justify-between">
      <Heading size={'xl'}>Filter</Heading>
      <Button
        variant={'link'}
        size={'xs'}
        className={'mb-2 p-0'}
        onClick={() => clearAll()}
      >
        Clear All
      </Button>
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const searchFilter = fashionWeekFilters?.search;

  const debouncedLoadOptions = debounce(loadFashionWeekSearchOptions, 300);

  return (
    <>
      <Heading size={'xl'}>Search</Heading>
      <Spacing size={1.125} />
      <FormInput>
        <SelectDropdown
          placeholder={searchFilter?.placeholder}
          isSearchable={searchFilter?.isSearchable}
          options={searchFilter?.options}
          multiselect={false}
          isClearable={searchFilter?.isClearable}
          value={searchFilter?.value}
          key={'search'}
          onChange={(e) => handleFashionWeekFiltersOnChange(e, 'search')}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={debouncedLoadOptions}
          defaultOptions
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          noOptionsMessage={() => 'No results found'}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </>
  );
}

export function ExploreSocialMediaSearch() {
  const { socialMediaFilters, handleSocialMediaFiltersOnChange } =
    useExploreContext();

  const search = socialMediaFilters?.search;

  return (
    <>
      <Heading size={'xl'}>Search</Heading>
      <Spacing size={1.125} />
      <input
        placeholder={search.placeholder}
        value={search.value}
        onChange={(e) =>
          handleSocialMediaFiltersOnChange(e.target.value, 'search')
        }
        className="w-full border-2 border-charcoal bg-white px-4 py-2 outline-none"
      />
    </>
  );
}

export function ExploreFashionWeekFilters() {
  const { fashionWeekFilters, handleFashionWeekFiltersOnChange } =
    useExploreContext();

  return (
    <>
      <ExploreFashionWeekSearch />

      <Spacing size={1.125} />

      <FiltersHeading />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        {Object.entries(fashionWeekFilters).map(
          ([filterKey, filter], i) =>
            filterKey !== 'search' && (
              <FormInput key={i}>
                <FormLabel>{filter?.label}</FormLabel>
                <SelectDropdown
                  placeholder={filter?.placeholder}
                  isSearchable={filter?.isSearchable}
                  options={filter?.options}
                  multiselect={false}
                  isClearable={filter?.isClearable}
                  value={filter?.value}
                  key={filterKey}
                  onChange={(e) =>
                    handleFashionWeekFiltersOnChange(e, filterKey)
                  }
                />
              </FormInput>
            ),
        )}
      </div>
    </>
  );
}

function SortHeading() {
  return (
    <div className="flex items-end justify-between">
      <Heading size={'xl'}>Sort</Heading>
    </div>
  );
}

export function ExploreSocialMediaFilters() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const {
    socialMediaFilters,
    handleSocialMediaFiltersOnChange,
    socialMediaSort,
    handleSocialMediaSortOnChange,
  } = useExploreContext();

  if (!exploreAdvanced) {
    delete socialMediaFilters.personCount;
    delete socialMediaFilters.personPosition;
  }
  const selectedTones = socialMediaFilters.color?.value || [];
  return (
    <>
      <ExploreSocialMediaSearch />

      <Spacing size={1.125} />

      <FiltersHeading />
      <Spacing size={1.125} />
      <div className="flex flex-col gap-4 pb-60">
        {Object.entries(socialMediaFilters).map(
          ([filterKey, filter], i) =>
            filterKey !== 'search' &&
            filterKey !== 'audience' &&
            (filterKey !== 'platform' ||
              socialMediaFilters.market?.value !== null) && (
              <FormInput key={i}>
                <FormLabel>{filter?.label}</FormLabel>
                <SelectDropdown
                  placeholder={filter?.placeholder}
                  isSearchable={filter?.isSearchable}
                  options={filter?.options}
                  multiselect={false}
                  isClearable={filter?.isClearable}
                  isMulti={filter?.isMulti}
                  value={
                    filterKey === 'market'
                      ? socialMediaFilters.market.value === null
                        ? null
                        : socialMediaFilters?.market?.value
                      : filter?.value
                  }
                  key={filterKey}
                  onChange={(e) =>
                    handleSocialMediaFiltersOnChange(e, filterKey)
                  }
                  {...(filterKey === 'color' && {
                    formatOptionLabel: (option) => {
                      const labelLowerCase = option.label.toLowerCase();
                      const tone = selectedTones.find(
                        (tone) => tone.value === option.value,
                      );
                      const backgroundColor = Object.keys(colorMap).includes(
                        labelLowerCase,
                      )
                        ? colorMap[labelLowerCase]
                        : tone
                          ? tone.hex
                          : option.value;

                      return (
                        <div
                          className={'flex flex-row items-center gap-3'}
                          style={{
                            margin: '1px 0',
                          }}
                        >
                          <div
                            className={cn(
                              'h-4 w-4 border-charcoal rounded-full',
                              option.value === 'white' && 'border-2',
                            )}
                            style={{
                              background: backgroundColor,
                            }}
                          ></div>
                          <span>{option.label}</span>
                        </div>
                      );
                    },
                  })}
                  {...(filterKey === 'tone' && {
                    formatOptionLabel: (option) => {
                      return (
                        <div
                          className={
                            '-my-2 -mx-4 flex h-full flex-row items-center'
                          }
                        >
                          <div className="flex flex-row items-center gap-2 px-4 py-2">
                            <div
                              className={cn(
                                'h-4 w-4 border-charcoal rounded-full',
                              )}
                              style={{
                                background: option.hex,
                                border: '1px solid black',
                              }}
                            ></div>
                            <span className="p-1">
                              {stringFormatter(option.label)} -{' '}
                              {option.code || option.value}
                            </span>
                          </div>
                        </div>
                      );
                    },
                  })}
                  {...(filterKey === 'market' && {
                    formatOptionLabel: (option) => {
                      return (
                        <div
                          className={
                            '-my-2 -mx-4 flex h-full flex-row items-center'
                          }
                        >
                          <div className="flex flex-row items-center gap-2 px-4 py-2">
                            {option.label + ', ' + option.location}
                          </div>
                        </div>
                      );
                    },
                  })}
                />
              </FormInput>
            ),
        )}
        {exploreAdvanced && (
          <>
            <Spacing size={1.125} />
            <SortHeading />
            {Object.entries(socialMediaSort).map(([sortKey, sort], i) => (
              <FormInput key={i}>
                <SelectDropdown
                  placeholder={sort?.placeholder}
                  isSearchable={false}
                  options={sort?.options}
                  multiselect={false}
                  isClearable={true}
                  value={sort?.value}
                  key={sort?.key}
                  onChange={(e) => handleSocialMediaSortOnChange(e, sortKey)}
                />
              </FormInput>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export function ExploreFilters({ className }) {
  const { activeTab } = useExploreContext();

  const filters = {
    'fashion-weeks': <ExploreFashionWeekFilters />,
    'social-media': <ExploreSocialMediaFilters />,
  };

  return (
    <>
      <Sidebar variant={'primary'}>{filters[activeTab]}</Sidebar>
    </>
  );
}
