import { useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { useLazyQuery, useMutation } from '@apollo/client';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { RefreshCw, Trash } from 'lucide-react';

import { UPDATE_FILE_STATUS } from '@/api/studio/mutations';
import { GENERATE_FILE_UPLOAD_URL } from '@/api/studio/queries';
import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarTitle } from './SidebarMenu';

TimeAgo.addDefaultLocale(en);

export const GenerationElapsed = () => {
  const { designTask } = useStudioContext();
  return (
    <span className={'tabular-nums'} style={{ fontSize: 14, color: '#a1a1a1' }}>
      <ReactTimeAgo
        date={designTask?.startedAt}
        timeStyle={{
          steps: [
            {
              formatAs: 'second',
            },
          ],
          labels: 'mini',
        }}
        tooltip={false}
      />
    </span>
  );
};

export const UploadBox = ({
  title,
  icon: Icon,
  text,
  type,
  fileVisibility = 'CONFIDENTIAL',
}) => {
  const [generateFileUploadUrl] = useLazyQuery(GENERATE_FILE_UPLOAD_URL);
  const [updateFileStatus] = useMutation(UPDATE_FILE_STATUS);
  const [uploading, setUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { setReferenceImage, setReferencePattern, setReferenceError } =
    useStudioContext();

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];

    if (!file) {
      setReferenceError('No file selected');
      return;
    }

    try {
      setUploading(true);
      setReferenceError(null);

      const { data, error: queryError } = await generateFileUploadUrl({
        variables: {
          fileVisibility,
          fileName: file.name,
          mimeType: file.type,
        },
      });

      if (queryError) {
        if (queryError.message === 'FILE_UPLOAD_IN_PROGRESS') {
          setReferenceError('This file is already being uploaded');
        } else {
          setReferenceError('This file cannot be uploaded');
        }
      }

      if (data?.generateFileUploadUrl?.signedUrl) {
        const response = await fetch(data.generateFileUploadUrl.signedUrl, {
          method: 'PUT',
          body: file,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        if (response.status === 200) {
          await updateFileStatus({
            variables: {
              fileId: data.generateFileUploadUrl.entityId,
              status: 'DONE',
            },
          });
          setUploadedImage({
            id: data.generateFileUploadUrl.entityId,
            name: file.name,
            type: type === 'image' ? 'image' : 'pattern',
          });
          if (type === 'image') {
            setReferenceImage({
              id: data.generateFileUploadUrl.entityId,
              name: file.name,
            });
          }
          if (type === 'pattern') {
            setReferencePattern({
              id: data.generateFileUploadUrl.entityId,
              name: file.name,
            });
          }
        }
      } else {
        throw new Error('Failed to get signed URL');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    setReferenceError(false);
  };

  return (
    <div className="flex w-1/2 flex-col gap-2">
      <SidebarTitle text={title} />
      {uploadedImage ? (
        <div className="group relative">
          <img
            src={uploadedImage.url}
            alt={uploadedImage.name}
            className="h-auto w-full rounded"
          />
          <div className="absolute inset-0 bg-night/40 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></div>
          <div className="absolute top-2 right-2 flex gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <label className="cursor-pointer rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
              <RefreshCw size={20} />
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                accept="image/*"
              />
            </label>
            <button
              className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-red-600 hover:bg-white hover:text-red-600"
              onClick={handleDeleteImage}
            >
              <Trash size={20} />
            </button>
          </div>
        </div>
      ) : (
        <label className="focus-visible:none flex cursor-pointer flex-col items-center justify-center gap-y-2 rounded border border-night/5 bg-secondary/5 p-5 text-center text-sm text-night hover:border-primary hover:bg-[#FFFDFD] hover:text-primary focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none">
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept="image/*"
            disabled={uploading}
          />
          <Icon size={16} />
          {uploading ? 'Uploading...' : text}
        </label>
      )}
    </div>
  );
};
