import React from 'react';
import numeral from 'numeral';
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

export const TrendsMetricsChart = ({ metric, metricList }) => {
  const data = [
    { name: 'Black Lose Jacket', value: 15.1 },
    { name: 'Wide-Leg Denim Pants', value: 75.7 },
    { name: 'Red T-Shirt', value: 25.3 },
    { name: 'Yellow Sweatshirt', value: 40.4 },
    { name: 'Black Plain Short', value: 23.4 },
    { name: 'Gray Sweatshirt', value: 10.4 },
  ];
  const COLORS = [
    '#68AAE3',
    '#3EA67F',
    '#E5EEFF',
    '#DEF2EB',
    '#C15F2A',
    '#FFDEBE',
  ];

  const metricListMock = [
    {
      color: COLORS[0],
      label: data[0].name,
      value: data[0].value,
    },
    {
      color: COLORS[1],
      label: data[1].name,
      value: data[1].value,
    },
    {
      color: COLORS[2],
      label: data[2].name,
      value: data[2].value,
    },
    {
      color: COLORS[3],
      label: data[3].name,
      value: data[3].value,
    },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const currentIndex = data.findIndex(
        (item) => item.name === payload[0].name,
      );
      return (
        <div className="flex items-center rounded-md border border-zinc-400/20 bg-white/90 p-2 shadow-md">
          <span
            className="inline-block h-1 rounded-full p-1.5"
            style={{ background: COLORS[currentIndex] }}
          ></span>
          {metric === 'Visibility' ? (
            <p className="label text-sm capitalize leading-5 text-night">{`${payload[0].name}: ${payload[0].value}%`}</p>
          ) : (
            <p className="label text-sm capitalize leading-5 text-night">{`${payload[0].name}: ${numeral(payload[0].value).format('0.0a').toUpperCase()}`}</p>
          )}
        </div>
      );
    }

    return null;
  };

  const CustomLabel = () => {
    const lines = metric.split(' ');
    return (
      <text
        x="45%"
        y="45%"
        fill="#000000"
        dominantBaseline="middle"
        textAnchor="middle"
        className="gap-y-2 text-base font-semibold leading-5 text-night"
      >
        {lines.map((line, index) => (
          <tspan
            key={index}
            x="45%"
            y="42%"
            dy={`${index * 1.2 - ((lines.length - 1) / 2) * 0.6}em`}
          >
            {line}
          </tspan>
        ))}
      </text>
    );
  };

  return (
    <div className="flex w-full flex-row">
      <div className="flex w-2/5 items-center justify-center" id="chart">
        <PieChart width={240} height={250}>
          <Pie
            data={metricList}
            cx={100}
            cy={100}
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            className="focus:border-none focus:outline-none"
          >
            {metricList.length > 0
              ? metricList.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    className="focus:border-none focus:outline-none"
                  />
                ))
              : data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    className="focus:border-none focus:outline-none"
                  />
                ))}
            <Label content={<CustomLabel />} />
          </Pie>
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: 'transparent' }}
          />
        </PieChart>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="px-2"> </TableHead>
            <TableHead className="w-3/4 px-2 font-normal">Trends</TableHead>
            <TableHead className="w-1/4 px-2 text-right font-normal">
              {metric}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {metricList.length > 0
            ? metricList.map((metricData, index) => (
                <TableRow className="border-none" key={index}>
                  <TableCell className="p-2">
                    <span
                      className="h-4 w-4 rounded-full capitalize"
                      style={{ background: metricData.color }}
                    />
                  </TableCell>
                  <TableCell className="p-2 font-medium capitalize">
                    {metricData.name}
                  </TableCell>
                  <TableCell className="p-2 text-right font-medium capitalize">
                    {metric === 'Visibility'
                      ? metricData.value
                      : numeral(metricData.value).format('0.0a').toUpperCase()}
                    {metric === 'Visibility' ? '%' : ''}
                  </TableCell>
                </TableRow>
              ))
            : metricListMock.map((metric, index) => (
                <TableRow
                  className="border-none text-base capitalize leading-5 text-night"
                  key={index}
                >
                  <TableCell className="flex items-center justify-center p-2 capitalize">
                    <span
                      className="mt-1.5 rounded-full p-1.5"
                      style={{ background: metric.color }}
                    ></span>
                  </TableCell>
                  <TableCell className="p-2 font-medium capitalize">
                    {metric.label}
                  </TableCell>
                  <TableCell className="p-2 text-right font-medium capitalize">
                    {metric.value}%
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </div>
  );
};
