import * as Tabs from '@radix-ui/react-tabs';

import { Heading } from '@/components/Heading';
import { cn } from '@/helpers/utils';

export function TabHeading({ label, value, size = 'xl' }) {
  return (
    <Tabs.Trigger
      value={value}
      className={cn(
        'data-[state=active]:text-primary text-secondary border-b-2 border-transparent data-[state=active]:border-primary hover:border-secondary transition',
      )}
    >
      <Heading size={size} className={'px-2 text-inherit'}>
        {label}
      </Heading>
    </Tabs.Trigger>
  );
}
