import React from 'react';

import { useCollectionsQuery } from '@/api/collections/hooks';
import { Container, Text } from '@/components';
import {
  PageHeader,
  PageHeaderDescription,
  PageHeaderTitle,
} from '@/components/PageHeader';
import { cn } from '@/helpers/utils';

import CollectionCard from './components';

import styles from './index.module.css';

const MyCollectionsLoadingScreen = () => {
  return (
    <div className="flex flex-col gap-8 p-[3.25rem]">
      <MyCollectionsPageHeader />
      <div className="mt-10 grid grid-cols-3 gap-7">
        {Array.from({ length: 6 }).map((_, index) => (
          <CollectionCard.Root key={index} skeleton>
            <CollectionCard.Images skeleton />
            <CollectionCard.Footer skeleton />
          </CollectionCard.Root>
        ))}
      </div>
    </div>
  );
};

const MyCollectionsPageHeader = () => {
  return (
    <PageHeader>
      <div className="flex items-center justify-between">
        <PageHeaderTitle>
          <div className={'flex gap-x-2 capitalize'}>Collections</div>
        </PageHeaderTitle>
        {/* <PageHeaderLink to="/create-trend-report/select-a-plan"> */}
        {/*   Create a New Report + */}
        {/* </PageHeaderLink> */}
      </div>
      <PageHeaderDescription>
        Here are the yours and your organization&#39;s collections. <br />
        You can create a new collection by clicking the &#34;+&#34; button next
        to any item you like.
      </PageHeaderDescription>
    </PageHeader>
  );
};

export const MyCollections = () => {
  const { data, loading, error } = useCollectionsQuery('collections', {
    variables: {
      cursor: null,
      includeOrganizationViewable: false,
    },
  });

  if (loading) {
    return <MyCollectionsLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (!data?.collections?.data.length) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-2 bg-gray-50">
        <span className={'text-2xl font-semibold'}>
          Oops! No Collections Yet 😊
        </span>
        <span className={'text-md'}>
          To start your own collection, simply click the &#34;Save&#34; or
          &#34;+&#34; button next to any item you like.
        </span>
      </div>
    );
  }

  return (
    <Container mainPage>
      <MyCollectionsPageHeader />

      <div className={cn(styles.cardsWrapper, 'mt-10')}>
        {data?.collections?.data.map((collection) => (
          <CollectionCard.Root
            key={collection.id}
            to={`/collections/${collection.id}/${encodeURIComponent(collection.name)}`}
          >
            <CollectionCard.Images
              images={collection.featuredImages}
              totalItemCount={collection.itemsCount}
            />
            <CollectionCard.Footer>
              <Text className="!text-[1.5rem]" weight="semibold">
                {collection.name}
              </Text>

              <Text className="text-gray-500">{collection.owner.fullName}</Text>
            </CollectionCard.Footer>
          </CollectionCard.Root>
        ))}
      </div>
    </Container>
  );
};
