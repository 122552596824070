import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from 'ahooks';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import { Button } from '@/components/Button.jsx';
import { Heading } from '@/components/Heading';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { BlurhashImage } from '@/pages/general/explore/components';

const orientationToWidth = {
  portrait: 'aspect-[3/4]',
  landscape: 'aspect-[4/3]',
  square: 'aspect-[1/1]',
};

export const StudioDesignTag = ({ label, className, ...props }) => {
  return (
    <span
      className={cn(
        'inline-block rounded-full bg-primary/50 px-4 py-2 text-sm font-medium text-charcoal text-left leading-tight',
        className,
      )}
      {...props}
    >
      {label}
    </span>
  );
};

const StudioDesignTagLoading = () => {
  return (
    <div
      className={
        'ghost animate-pulse rounded-full px-2 py-1 text-sm font-medium'
      }
    >
      Lorem
    </div>
  );
};

export function StudioDesignImage({ image, designTask }) {
  const responsive = useResponsive();
  const {
    setPayload,
    // setImagePopup
  } = useStudioContext();

  const [isHovering, setIsHovering] = useState(false);
  const { isExpertMode, headingText, tags } = getStudioTaskDetails(designTask);

  const navigate = useNavigate();

  return (
    <div
      className={cn('flex relative')}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <BlurhashImage
        image={image}
        height={responsive.xl ? 320 : responsive.lg ? 256 : 320}
      />

      {isHovering && (
        <div className="absolute h-full w-full overflow-hidden opacity-100">
          <div
            className="absolute inset-0 bg-primary opacity-50"
            style={{
              WebkitMask:
                'linear-gradient(0deg, rgb(255, 255, 255) 50px, rgb(255, 255, 255) 100px, rgba(255, 255, 255, 0.9) 150px, rgba(255, 255, 255, 0) 200px)',
              borderRadius: 2,
            }}
          />
          <div
            className="absolute inset-0 z-20 backdrop-blur-[50px] backdrop-brightness-75 backdrop-saturate-200"
            style={{
              WebkitMask:
                'linear-gradient(0deg, rgb(255, 255, 255) 100px, rgb(255, 255, 255) 150px, rgba(255, 255, 255, 0.9) 185px, rgba(255, 255, 255, 0) 275px)',
              borderRadius: 2,
            }}
          />
          <div className="absolute inset-0 bg-gradient-to-t opacity-10" />
          <div
            className="absolute inset-0"
            style={{
              WebkitMask:
                'linear-gradient(0deg, rgb(255, 255, 255) 50px, rgb(255, 255, 255) 100px, rgba(255, 255, 255, 0.9) 150px, rgba(255, 255, 255, 0) 200px)',
              borderRadius: 2,
            }}
          >
            <div
              className="relative h-full w-full opacity-50"
              style={{
                borderWidth: '60px 60px 520px',
                borderStyle: 'solid',
                borderColor: 'transparent',
                borderImage: `url("${image.url}") 20 1 / 1 / 0 stretch`,
              }}
            />
          </div>

          <div
            className={
              'font-regular absolute bottom-0 left-0 z-50 flex h-full w-full flex-col justify-end gap-y-2 p-4  align-baseline text-sm text-white drop-shadow'
            }
          >
            <div className={'flex flex-col gap-2 font-semibold'}>
              {designTask?.createdBy?.fullName || 'Anonymous'}
            </div>

            {isExpertMode ? (
              <span className={'line-clamp-3'}>
                {designTask?.payload?.positivePrompt}
              </span>
            ) : (
              <span className={'line-clamp-3'}>
                {headingText}, {tags.map((item, i) => item.label).join(', ')}
              </span>
            )}

            <div className={'mt-1 flex flex-nowrap gap-2'}>
              <Button
                variant={'transparent'}
                size={'xs'}
                outline={true}
                block
                className={'p-0'}
                onClick={() => {
                  if (!designTask || !designTask?.payload) return;
                  const payload = { ...designTask?.payload };
                  if (payload?.design) {
                    payload.design = payload.design?.filter(Boolean);
                  }
                  setPayload(payload);
                }}
              >
                Edit Prompt
              </Button>
              <Button
                variant={'transparent'}
                size={'xs'}
                outline={true}
                block
                onClick={() => {
                  navigate(
                    `/studio/image-preview/studioItem/${image.studioItemId || designTask.id}`,
                  );
                }}
              >
                View
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function StudioDesignImagesContainer({ children, className, ...props }) {
  return (
    <div
      className={cn(
        'flex w-full max-w-full flex-nowrap items-start justify-start gap-4',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

const getStudioTaskDetails = (designTask) => {
  const payload = cloneDeep(designTask?.payload);

  const isExpertMode = payload?.mode === 'EXPERT';

  const tags = isExpertMode
    ? [
        { label: `Prompt: ${payload?.positivePrompt}` },
        payload?.negativePrompt
          ? { label: `Negative Prompt: ${payload?.negativePrompt}` }
          : false,
      ].filter(Boolean)
    : [
        payload?.design,
        payload?.concept
          ? { label: `Concept: ${payload?.concept?.label}` }
          : false,
        payload?.location ? { label: `Scene: ${payload?.location}` } : false,
      ]
        .flat()
        .filter(Boolean);

  if (payload?.color && !Array.isArray(payload?.color)) {
    payload.color = [payload?.color];
  }

  const headingText = isExpertMode
    ? 'Expert Mode'
    : `${payload?.color?.map((c) => c.label).join(' ') || ''} ${
        payload?.item?.label
      }`;

  const Tags = tags.map((item, i) => (
    <div key={i}>
      <StudioDesignTag label={item?.label} />
    </div>
  ));

  return {
    isExpertMode,
    headingText,
    Tags,
    tags,
  };
};

export function StudioDesignTaskContent({ designTask }) {
  const { isExpertMode, headingText, Tags } = getStudioTaskDetails(designTask);

  return (
    <div className={'flex w-full flex-col'}>
      <div className="mb-4 flex items-start justify-between">
        <div className={'flex grow flex-col'}>
          <Heading
            size={'lg'}
            variant={'secondary'}
            className={'mb-2 font-medium leading-none'}
          >
            {isExpertMode ? 'Expert Mode' : headingText}
          </Heading>
          <div className={'mt-1 flex h-auto flex-wrap items-center gap-2'}>
            {Tags}
          </div>
        </div>
        <span className={'text-right text-sm font-medium text-secondary'}>
          {dayjs(designTask?.createdAt).format('DD MMM YYYY')}
          <br />
          {dayjs(designTask?.createdAt).format('HH:mm')}
        </span>
      </div>

      <StudioDesignImagesContainer>
        {designTask?.images.map((item, i) => (
          <StudioDesignImage key={i} image={item} designTask={designTask} />
        ))}
      </StudioDesignImagesContainer>
    </div>
  );
}

export function StudioDesignTask({ designTask }) {
  const loading = designTask?.loading || false;

  if (designTask?.loading) {
    return (
      <StudioDesignTaskLoading
        payload={designTask?.payload}
        orientation={designTask?.payload?.orientation?.value}
      />
    );
  }

  if (!loading && designTask?.images) {
    return <StudioDesignTaskContent designTask={designTask} />;
  }

  return <></>;
}

export function StudioDesignImageLoading({ orientation = 'portrait' }) {
  const responsive = useResponsive();

  return (
    <div
      className={cn('flex flex-wrap ghost', orientationToWidth[orientation])}
      style={{
        height: responsive.xl ? 320 : responsive.lg ? 256 : 320,
      }}
    ></div>
  );
}

export function StudioDesignImagesLoading({ orientation = 'portrait' }) {
  return (
    <div className={'flex w-full gap-4'}>
      {Array(4)
        .fill(0)
        .map((item, i) => (
          <StudioDesignImageLoading key={i} orientation={orientation} />
        ))}
    </div>
  );
}

export function StudioDesignTaskLoading({
  payload = false,
  orientation = 'portrait',
}) {
  const { headingText, Tags } = getStudioTaskDetails({
    payload: payload || {},
    loading: true,
  });

  return (
    <div className={'flex h-full columns-4 flex-row gap-4'}>
      <div className={'flex w-full flex-col'}>
        <div className="mb-4 flex items-start justify-between">
          <div className={cn('flex grow flex-col', !payload && 'ghost')}>
            <Heading
              size={'lg'}
              variant={'secondary'}
              className={'mb-2 font-medium leading-none'}
            >
              {payload ? headingText.trim() : 'Lorem ipsum'}
            </Heading>
            <div className={'mt-1 flex h-auto flex-wrap items-center gap-2'}>
              {payload ? Tags : <StudioDesignTagLoading />}
            </div>
          </div>
        </div>

        <StudioDesignImagesLoading orientation={orientation} />
      </div>
    </div>
  );
}
