import { cn } from '@/helpers/utils.js';

export const AsideBarButton = ({ icon: Icon, text, onClick, active }) => {
  return (
    <div
      className={cn(
        'flex flex-col cursor-pointer items-center justify-center text-night text-sm leading-3 font-medium bg-white focus-visible:none focus:ring-0 rounded p-2 hover:bg-primary hover:text-white focus:border-primary focus-visible:outline-none gap-y-2 transition duration-300 ease-in-out',
        active && 'bg-primary text-white',
      )}
      onClick={onClick}
    >
      <Icon size={20} />
      {text}
    </div>
  );
};
