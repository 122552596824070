import { gql } from '@apollo/client';

export const GENERATE_STUDIO_ITEM = gql`
  mutation GenerateStudioItem($payload: GenerateImageInput!) {
    generateStudioItem(payload: $payload) {
      id
    }
  }
`;

export const STUDIO_TASK = gql`
  mutation studioTask($id: ID!) {
    studioTask(id: $id) {
      id
      seed
      images {
        url
        width
        height
        blurhash
        studioItemId
      }
      organization {
        id
        name
      }
      createdBy {
        id
        fullName
      }
      visibility
      status
    }
  }
`;

export const UPDATE_FILE_STATUS = gql`
  mutation Mutation($fileId: ID!, $status: String!) {
    updateFileStatus(fileId: $fileId, status: $status)
  }
`;

export const NEW_PROMPT_SUGGESTION = gql`
  mutation StudioSuggestNewPrompts($prompt: String!) {
    studioSuggestNewPrompts(prompt: $prompt) {
      prompt
      suggestions {
        key
        value
      }
    }
  }
`;
