import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { FlexRow } from '@/components';

import { useCard } from '../context';

import styles from '../index.module.css';

export function Footer({ id, exploreItemId, activeTab }) {
  const itemType =
    activeTab === 'fashion-weeks' ? 'fashionWeekItem' : 'socialMediaItem';
  const { data } = useCard();

  const navigate = useNavigate();

  return (
    <FlexRow justify="between" items="center" className="z-[50] h-full pt-6">
      <div
        className="mt-2 flex h-full w-full cursor-pointer items-end justify-between bg-transparent"
        onClick={() =>
          navigate(
            `/explore/image-preview/${itemType}/${id}${itemType === 'socialMediaItem' ? `?exploreItem=${exploreItemId}` : ''}`,
          )
        }
      >
        <div className="flex grow flex-col gap-1">
          <h3
            className={styles.title}
            style={{
              filter:
                'drop-shadow(0 2px 3px rgb(0 0 0 / 0.5)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.3))',
            }}
          >
            {data.title || data.designer?.replaceAll('-', ' ')}
          </h3>

          <div className="flex grow">
            <div className={'block min-w-0 gap-0.5 text-xs font-semibold'}>
              {data?.attributes?.map((attribute, index) => {
                if (attribute.value === 'N/A') return null;

                return (
                  <div className={'mt-1 mr-1 inline-block'} key={index}>
                    <div
                      className={
                        'mt-0.25 text-shadow inline-block rounded-full border border-primary bg-primary/25 px-1.5 py-0.5 text-white drop-shadow '
                      }
                      key={index}
                    >
                      <span className={'leading-none'}>
                        {capitalize(attribute.value.replaceAll('-', ' '))}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </FlexRow>
  );
}
