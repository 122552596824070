import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Check } from '@/assets/svg';
import { cn } from '@/helpers/utils';
dayjs.extend(relativeTime);

export const CollectionItem = ({
  item,
  selectedCollection,
  setSelectedCollection,
  handleSave,
  alreadySaved,
  handleRemove,
}) => {
  const isSelected = selectedCollection === item?.id;

  return (
    <div
      className={`${isSelected && 'border-2 border-[#c29b77]'} ${
        alreadySaved && '!border-primary'
      } group mb-[12px] flex min-h-[82px] w-full cursor-pointer border-2 border-[#e9e9e9] hover:border-primary`}
      value={item?.name}
      onClick={() => {
        isSelected
          ? setSelectedCollection(null)
          : setSelectedCollection(item?.id);
        alreadySaved ? handleRemove(item?.id) : handleSave(item?.id);
      }}
    >
      <div
        className={`${
          alreadySaved && '!border-primary'
        } grid min-w-[80px] grid-cols-2 border-r-2 border-[#e9e9e9] p-[6px] group-hover:border-primary`}
      >
        {item.featuredImages.slice(0, 4).map((item, index) => (
          <img
            key={index}
            className="h-[32px] w-[32px]"
            src={item?.url}
            alt=""
          />
        ))}
      </div>

      <div className="relative flex w-full flex-col pl-[16px] leading-[18px]">
        <div className="border-r-0 pt-[16px] text-[18px] font-semibold leading-[27px]">
          {item.name}
        </div>
        <div className="font-book flex w-full items-center gap-1 text-[12px] text-[#c7c7c7]">
          <span>{item.owner?.fullName || 'Unknown'}</span>✴
          <span>
            {item.itemsCount} shots ✴ updated {dayjs(item.updatedAt).fromNow()}
          </span>
        </div>

        {(isSelected || alreadySaved) && (
          <div className="absolute right-[14px] top-[23px]">
            <Check />
          </div>
        )}
      </div>
    </div>
  );
};

export function CollectionItemBeta({
  featuredImages,
  name,
  itemsCount,
  alreadySaved,
  owner,
  id,
  onCollectionSelect,
  selectedCollection,
}) {
  const images = featuredImages.slice(0, 4);
  const lastImage = featuredImages.length === 4 ? featuredImages.pop() : null;

  const isSelected = selectedCollection === id;

  return (
    <div
      className={cn(
        'w-full flex flex-col h-full cursor-pointer transition-all',
        alreadySaved && 'border-primary cursor-not-allowed opacity-60',
      )}
      onClick={() => {
        if (alreadySaved) return;

        onCollectionSelect(id);
      }}
    >
      <div
        className={cn(
          'grid grid-cols-2 gap-2 h-full relative transition-all',
          isSelected && 'ring-primary ring-2 p-2',
        )}
      >
        {images.length === 0 && (
          <div className="absolute inset-0 z-20 flex items-center justify-center bg-black/20">
            <h1 className="text-xl font-semibold text-white">
              No Images Found
            </h1>
          </div>
        )}
        {images.length > 0
          ? images.map((img, index) => (
              <img
                key={index}
                className="aspect-square w-full object-contain"
                src={img?.url}
                alt=""
              />
            ))
          : Array.from({ length: 3 }).map((_, i) => (
              <div className="aspect-square w-full bg-slate-300" key={i} />
            ))}
        <div className="relative aspect-square w-full">
          {lastImage ? (
            <img className="aspect-square w-full" src={lastImage?.url} alt="" />
          ) : (
            <div className="aspect-square w-full bg-slate-300"></div>
          )}

          {itemsCount > 4 && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/30 text-lg font-medium text-white">
              {itemsCount - 3} more items
            </div>
          )}
        </div>
      </div>

      {/* <div className="w-full h-px bg-black my-4"></div> */}

      <div
        className={cn(
          'ring-1 ring-black flex flex-col mt-2 p-4 transition-all',
          isSelected && 'ring-primary ring-2',
        )}
      >
        <h1 className="text-xl font-medium">{name}</h1>

        <div className="my-1 h-px w-full bg-black" />

        <span>Owner: {owner.fullName}</span>
        <span>Total {itemsCount} item</span>
      </div>
    </div>
  );
}
