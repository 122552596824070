import React, { useState } from 'react';
import { CircleAlert } from 'lucide-react';

import { useExploreMutation } from '@/api/explore/hooks';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { DropdownMenuItem } from '.';

export const ReportButton = ({ id: itemId, preview }) => {
  const [reason, setReason] = useState('Wrong apparel');
  const [message, setMessage] = useState('');

  const [mutation] = useExploreMutation('reportItem');

  const reportReasons = [
    'Wrong apparel',
    'Missing apparel',
    'Wrong color/color tone',
    'Wrong design detail',
    'Inappropriate',
  ];

  const handleSubmit = () => {
    console.log(`Submitted Report: ${reason} with comment: ${message}`);
    mutation({
      variables: {
        itemId,
        itemType: 'SOCIAL_MEDIA',
        message,
        reason,
      },
    }).then((res) => console.log(res.data?.reportItem?.status));
    setReason('Wrong apparel');
    setMessage('');
  };

  // if (import.meta.env.VITE_APP_ENV !== 'development') {
  //   return null;
  // }

  const buttonStyle = preview
    ? 'rounded-full border border-secondary/20 bg-secondary/5 p-2 focus:outline-none focus:ring-0'
    : 'flex h-8 w-8 items-center justify-center bg-white text-night opacity-100 hover:opacity-100 focus:outline-none focus:ring-0';

  const reportButtonStyle = (type) =>
    reason === type ? 'bg-primary/10 px-4 text-primary' : 'bg-white text-black';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <button className={buttonStyle}>
          <CircleAlert size={16} />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={4}
        className="gap-y-1 rounded-none bg-white text-black"
      >
        <div className="flex flex-col items-start justify-center">
          {reportReasons.map((type) => (
            <button
              key={type}
              onClick={() => setReason(type)}
              className={`${reportButtonStyle(type)} w-full py-2 px-4 text-left text-sm font-semibold hover:bg-primary/5 hover:text-primary`}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>
        <div className="p-2">
          <textarea
            type="text"
            placeholder="Add a comment (optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mb-2 w-full border py-2 px-4 text-sm focus:border-black focus:outline-none"
          />
          <DropdownMenuItem style={{ padding: 0, margin: 0 }}>
            <button
              onClick={handleSubmit}
              className="w-full bg-primary py-2 text-sm font-bold text-white hover:bg-primaryHover"
            >
              Submit
            </button>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
