import React from 'react';

import { cn } from '@/helpers/utils';

export function FormInput({ children, className, ...props }) {
  return (
    <div
      className={cn('flex w-full flex-col gap-[0.25rem]', className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function FormLabel({
  children,
  className,
  indent = false,
  required = false,
  ...props
}) {
  return (
    <label
      className={cn(
        'h-[1.75rem] pb-[0.25rem] text-base font-semibold',
        indent && 'pl-[1rem]',
        className,
      )}
      {...props}
    >
      {children}
      {required && <span className={'ml-0.5 text-red-700'}>*</span>}
    </label>
  );
}
