import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';
import c from 'classnames';

import {
  GET_COLLECTION_ITEMS_GROUPED,
  GET_COLLECTION_ITEMS_MERGED,
} from '@/api/collections/queries';
import { useAppContext } from '@/hooks/appContext';

import { Cards, Colors, Gallery, Header } from './components';

const Alert = ({ children }) => {
  return (
    <div className={'flex w-full justify-center bg-gray-100 p-4'}>
      {children}
    </div>
  );
};

const PredefinedAlert = ({ code }) => {
  const predefinedAlerts = {
    NO_TREND_ITEM: 'No trend item added to collection',
    NO_ITEM_IN_COLLECTION: "Collection doesn't have any item",
  };

  return <Alert>{predefinedAlerts[code] || 'Something went wrong'}</Alert>;
};

function TwoColumnView({ collection, data }) {
  return (
    <div className={'flex w-full animate-fade-in-fast gap-x-[30px]'}>
      <div className={'basis-6/12'}>
        {data.trendItems?.data?.length > 0 ? (
          <Cards data={data.trendItems.data} />
        ) : (
          <PredefinedAlert code={'NO_TREND_ITEM'} />
        )}
      </div>
      <div className={'basis-6/12'}>
        <GalleryView layout={0} collection={collection} />
      </div>
    </div>
  );
}

function ThreeColumnView({ collection, data, loadMore }) {
  return (
    <div className={'flex w-full animate-fade-in-fast gap-x-[30px]'}>
      <div className={'basis-2/12 flex-col'}>
        <div
          className={
            'mb-[1.5rem] text-[1.5rem] font-semibold leading-[1.5] underline underline-offset-8'
          }
        >
          Colors
        </div>
        <Colors data={data?.tones?.data || []} />
      </div>
      <div className={'basis-6/12 flex-col'}>
        <div
          className={
            'mb-[1.5rem] text-[1.5rem] font-semibold leading-[1.5] underline underline-offset-8'
          }
        >
          Items
        </div>
        {data.trendItems?.data?.length > 0 ? (
          <Cards data={data.trendItems.data || []} />
        ) : (
          <PredefinedAlert code={'NO_TREND_ITEM'} />
        )}
      </div>
      <div className={'basis-4/12 flex-col'}>
        <div
          className={
            'mb-[1.5rem] text-[1.5rem] font-semibold leading-[1.5] underline underline-offset-8'
          }
        >
          Images
        </div>
        <Gallery
          layout={1}
          data={data?.images?.data || []}
          loadMore={loadMore}
          collection={collection}
        />
      </div>
    </div>
  );
}

const GalleryViewLoadingScreen = ({ layout }) => (
  <div className={'flex w-full animate-pulse gap-x-[30px]'}>
    <div
      className={c(
        'w-full',
        layout === 0 && 'columns-3',
        layout === 1 && 'columns-2',
        layout === 2 && 'columns-6',
      )}
      style={{
        columnGap: '1.875rem',
        rowGap: '1.875rem',
        breakInside: 'avoid',
      }}
    >
      {Array(16)
        .fill()
        .map((_, index) => (
          <div
            key={'lazyload_' + index}
            className="mb-[1.875rem] w-full bg-gray-200"
            style={{
              height: Math.floor(Math.random() * (40 - 10 + 1) + 10) + 'rem',
            }}
          />
        ))}
    </div>
  </div>
);

export const GalleryView = ({ layout = 2, collection }) => {
  const [nextCursor, setNextCursor] = useState(null);

  const { error, fetchMore, networkStatus, data, refetch } = useQuery(
    GET_COLLECTION_ITEMS_MERGED,
    {
      variables: {
        collection,
        cursor: null,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setNextCursor(data?.collectionItemsMerged?.cursor);
      },
    },
  );

  useEffect(() => {
    refetch({
      collection,
      cursor: null,
    });

    return () => {
      setNextCursor(null);
    };
  }, [collection]);

  const collectionData = data?.collectionItemsMerged?.data || [];

  const loadMore = () => {
    if (networkStatus !== NetworkStatus.ready || !nextCursor) return;

    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    });
  };

  if (
    (networkStatus === NetworkStatus.loading && !collectionData.length) ||
    networkStatus === NetworkStatus.refetch
  ) {
    return <GalleryViewLoadingScreen layout={layout} />;
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Error: {error.message}</span>
      </div>
    );
  }

  if (!collectionData.length) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <PredefinedAlert code={'NO_ITEM_IN_COLLECTION'} />
      </div>
    );
  }

  return (
    <div className={'flex w-full animate-fade-in-fast gap-x-[30px]'}>
      <Gallery
        layout={layout}
        data={collectionData}
        collection={collection}
        loadMore={loadMore}
      />
    </div>
  );
};

export const MyCollection = () => {
  const { collectionsLayout } = useAppContext();
  const { id } = useParams();

  const [nextCursor, setNextCursor] = useState(null);

  const { error, networkStatus, fetchMore, data } = useQuery(
    GET_COLLECTION_ITEMS_GROUPED,
    {
      variables: {
        collection: id,
        cursor: null,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setNextCursor(data.collectionItemsGrouped.apparels.cursor);
      },
    },
  );

  const collectionData = data?.collectionItemsGrouped || [];

  // if (
  //   networkStatus === NetworkStatus.loading &&
  //   !collectionData?.apparels?.data?.length
  // ) {
  //   return (
  //     <div className="flex flex-col">
  //       <Header />
  //       <GalleryViewLoadingScreen layout={2} />
  //     </div>
  //   );
  // }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Error: {error.message}</span>
      </div>
    );
  }

  // if (!collectionData.length) return <div>Collection doesn&apos;t have any items</div>

  const loadMore = () => {
    if (networkStatus !== NetworkStatus.ready || !nextCursor) return;

    fetchMore({
      variables: {
        cursor: nextCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setNextCursor(fetchMoreResult.collectionItemsGrouped.apparels.cursor);

        return {
          collectionItemsGrouped: {
            ...fetchMoreResult.collectionItemsGrouped,
            apparels: {
              ...fetchMoreResult.collectionItemsGrouped.apparels,
              data: [
                ...prev.collectionItemsGrouped.apparels.data,
                ...fetchMoreResult.collectionItemsGrouped.apparels.data,
              ],
            },
            trendItems: {
              ...fetchMoreResult.collectionItemsGrouped.trendItems,
              data: [
                ...prev.collectionItemsGrouped.trendItems.data,
                ...fetchMoreResult.collectionItemsGrouped.trendItems.data,
              ],
            },
            tones: {
              ...fetchMoreResult.collectionItemsGrouped.tones,
              data: [
                ...prev.collectionItemsGrouped.tones.data,
                ...fetchMoreResult.collectionItemsGrouped.tones.data,
              ],
            },
          },
        };
      },
    });
  };

  const layoutToView = {
    'two-col': (
      <TwoColumnView
        collection={id}
        data={collectionData}
        loadMore={loadMore}
      />
    ),
    'three-col': (
      <ThreeColumnView
        collection={id}
        data={collectionData}
        loadMore={loadMore}
      />
    ),
    gallery: <GalleryView collection={id} />,
  };

  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex gap-x-[30px] px-[60px] py-[32px]">
        {layoutToView[collectionsLayout]}
        {/* {layout === 1 && <Colors data={collectionData} />} */}
        {/* {layout !== 2 && <Cards />} */}
        {/* <Gallery layout={layout} data={collectionData} /> */}
      </div>
    </div>
  );
};
