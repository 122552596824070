import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { cn } from '@/helpers/utils';

const buttonVariants = cva(
  'font-medium transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        primary:
          'bg-primary text-white hover:bg-primary-darker border-[2px] border-[transparent] hover:bg-primaryHover',
        primaryBordered:
          'bg-primary text-white hover:bg-primary-darker border-[2px] border-black hover:bg-primaryHover',
        primaryOutline:
          'bg-white text-primary border-[2px] border-primary hover:bg-gray-100',
        secondary: 'bg-black text-white hover:bg-gray-900',
        secondaryOutline:
          'bg-white text-black border-[2px] border-black hover:bg-gray-100',
        transparent: (color) =>
          `bg-transparent text-${color} font-semibold border-b-[2px] border-transparent hover:border-[#282928] px-0`,
        transparentOutline:
          'bg-black/20 text-white border-[1px] border-white hover:bg-black/30 transition',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'underline-offset-4 hover:underline text-primary',
        destructive: 'bg-red-500 text-white hover:bg-red-500/90',
        destructiveOutline:
          'border border-input hover:bg-destructive hover:text-destructive-foreground',
      },
      size: {
        xs: 'text-[0.85rem] px-[0.85rem] py-[0.4rem] leading-none',
        sm: 'text-[1rem] px-[1rem] pt-[0.5rem] pb-[0.48rem] leading-[1.2875rem]',
        md: 'text-[1.125rem] px-[1.125rem] pt-[0.6875rem] pb-[0.5625rem] leading-[1.6875rem]',
        lg: 'text-[1.5rem] px-[2rem] py-[1.125rem]',
      },
      block: {
        true: 'w-full',
      },
      icon: {
        true: 'flex justify-center items-center gap-x-[0.8125rem]',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
);

const Button = React.forwardRef(
  (
    {
      className,
      variant = 'primary',
      size = 'md',
      block,
      outline,
      color = 'default',
      icon,
      iconPosition = 'left',
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    const classNames = cn(
      buttonVariants({
        variant: outline ? `${variant}Outline` : variant,
        size,
        block,
        icon,
      }),
      icon && `icon-${iconPosition} flex items-center justify-center`,
      className,
    );

    return (
      <Comp
        className={classNames}
        ref={ref}
        type={props.type || 'button'}
        {...props}
      >
        {iconPosition === 'left' && icon}
        {props.children}
        {iconPosition === 'right' && icon}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
